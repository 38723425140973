import gcoord from 'gcoord'
import webMap from './map.web'
import { $t } from '../i18n'
import { trace } from '../trace'
// 鉴权过就不需要再鉴权了
let feishuConfiged = false
// 飞书app环境中定位
class Location_feishu {
  getAddress(callback) {
    let corpCode = sessionStorage.getItem('feishu_corp')
    if (!corpCode) {
      new webMap().getAddress(callback)
      // callback({code: -1, msg: '暂无飞书定义编码'})
      return
    }
    trace.log('location-msg', {
      from: 'feishu',
      msg: '开始定位: getAddress'
    })
    window.mconsole &&
      window.mconsole.upYueYing2(
        '定位-feishu',
        {
          ua: navigator.userAgent.toLowerCase(),
          userId: localStorage.getItem('userId')
        },
        199,
        0
      )

    if (feishuConfiged) {
      this.getLocation(callback)
      return
    }
    let that = this
    window.$httpUtil.instance
      .get('/auth/getAuthConfig.json', {
        params: {
          corp: corpCode,
          url: window.location.href,
          client: 'feishu'
        }
      })
      .then((res) => {
        let rs = res.data
        let data = rs.data
        if (rs.code != '0' || !data || !data.signature) {
          trace.log('location-msg', {
            from: 'feishu',
            msg: '定位鉴权失败: getAddress'
          })
          callback({ code: -1, msg: $t('wu-fa-huo-de-jian-quan-xin-xi') })
          return
        }
        window.h5sdk.config({
          appId: data.appId, // 必填，应用ID
          timestamp: data.timeStamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: [
            // 'device.geolocation.get'
          ], // 必填，需要使用的jsapi列表。
          onSuccess: function (result) {
            trace.log('location-msg', {
              from: 'feishu',
              msg: '定位鉴权成功: getAddress',
              data: result
            })
            // feishuConfiged = true
            that.getLocation(callback)
          },
          onFail: function (res) {
            trace.log('location-msg', {
              from: 'feishu',
              msg: '定位鉴权成功: getAddress',
              data: res
            })
            callback({ code: -1, msg: JSON.stringify(res) })
          }
        })
      })
  }
  getLocation(callback) {
    trace.log('location-msg', {
      from: 'feishu',
      msg: '开始定位: getLocation'
    })
    window.tt.getLocation({
      type: 'gcj02',
      timeout: 5,
      cacheTimeout: 20,
      accuracy: 'best',
      success(data) {
        data.from = 3
        Promise.resolve(data)
          .then(webMap.geoconv)
          .then((data) => webMap.geocoder(data, { coordtype: 'BD09', ret_coordtype: 'BD09' }))
          .then((res) => {
            trace.log('location-msg', {
              from: 'feishu',
              msg: '定位结束: getLocation',
              data: res
            })
            if (res && res.status === 0) {
              const ret = res.result
              const transPoint = gcoord.transform([ret.location.lng, ret.location.lat], gcoord.BD09, gcoord.GCJ02)
              let paramsMsg = {
                province: ret.addressComponent.province,
                city: ret.addressComponent.city || ret.addressComponent.district,
                district: ret.addressComponent.district,
                address: ret.formatted_address,
                lon: transPoint[0],
                lat: transPoint[1],
                code: '0'
              }
              trace.log('location-msg', {
                from: 'feishu',
                msg: '定位成功: getLocation',
                data: paramsMsg
              })
              callback(paramsMsg)
            } else {
              trace.log('location-msg', {
                from: 'feishu',
                msg: '定位失败: getLocation',
                data: { code: -1, msg: $t('feishu-huo-qu-wei-zhi-chu-cuo') }
              })
              callback({ code: -1, msg: JSON.stringify($t('feishu-huo-qu-wei-zhi-chu-cuo')) })
            }
          })
      },
      fail(res) {
        trace.log('location-msg', {
          from: 'feishu',
          msg: '定位失败: getLocation',
          data: { code: -1, msg: JSON.stringify(res) }
        })
        callback({ code: -1, msg: JSON.stringify(res) })
      }
    })
  }
}
export default Location_feishu
