// 公共工具初始化
// 原生对象拓展，兼容低版本浏览器语法
import './prototype_expand'
// 跳转
import './jump'
// 工具类
import './tool'
import { $t } from './i18n'
// 工具函数
// 企业用户配置数据统一入口
import ZConf from './ZConf/index.js'
// 时间处理工具
// import moment from 'moment'
// 云客服
import CS from './CS7M'
import gcoord from 'gcoord'
import Map from './map/index'
import WebMap from './map/map.web'
import { isHideOtherDomain } from '@/lib/libs'

// import Chat from '../views/chat/index'
// 不同环境中加载不同的资源
async function loadEnvJS(callback) {
  if ($jump.env.dd) {
    // 钉钉环境初始化
    let apiUrl = 'https://g.alicdn.com/dingding/dingtalk-jsapi/2.3.0/dingtalk.open.js'
    // 复星钉钉渠道
    if (location.search.indexOf('source=fosun') != -1 || localStorage.getItem('dd_source') == 'fosun') {
      apiUrl = 'https://g.alicdn.com/ilw/ding/0.8.9/scripts/dingtalk.js'
    }
    $tool.loadScript(apiUrl, function (rs) {
      if (rs && window.dd) {
        window.dd.biz.navigation.setTitle({
          title: $ZConfig.getVal('NAME')
        })
        window.dd.ready(callback)
        window.dd.error(function (err) {
          alert('dd error: ' + JSON.stringify(err))
        })
      } else {
        alert($t('WLYC2') + '[001]')
      }
    })
  } else if ($jump.env.weixin || $jump.env.miniprogram) {
    $tool.loadScript('https://res.wx.qq.com/open/js/jweixin-1.4.0.js', function (rs) {
      if (rs && window.wx) {
        callback()
      } else {
        let x = 0
        if (rs) {
          x = 1
        } else if (window.wx) {
          x = 2
        }
        alert($t('WLYC2') + '[002]' + x)
      }
    })
  } else if ($jump.env.feishu) {
    // 飞书应用中
    if (window.h5sdk) {
      // 飞书jsbug，须在index.html加载js
      window.h5sdk.ready(callback)
    } else {
      alert($t('WLYC2') + '[003]')
    }
  } else {
    callback()
  }
}
// 解决ios因软键盘弹出导致元素偏移的问题
function fixInputLayout() {
  const isIphone = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  let keybordShow = false
  document.body.addEventListener('focusin', (e) => {
    keybordShow = true
    setTimeout(function () {
      fixLayout(e)
    }, 520)
  })
  document.body.addEventListener('focusout', () => {
    keybordShow = false
    setTimeout(function () {
      fixLayout()
    }, 100)
  })
  function fixLayout(e) {
    if (isIphone) {
      if (!keybordShow) {
        window.scroll(document.body.scrollHeight, 0)
      }
    } else {
      if (e && e.target.nodeName == 'INPUT') {
        keybordShow && e.target.scrollIntoView(false)
      }
    }
  }
}

// vivo app未点击隐私条款时,不能获取用户设备信息; 百度地图, 极光推送可能会调用手机权限
export function lazyInit() {
  if (isHideOtherDomain()) {
    return
  }
  console.log('lazyInit.....')
  if (typeof window.isLazyInit !== 'undefined' && window.isLazyInit === true) {
    console.log('lazyInited....')
    return
  }

  if (window.api) {
    // 初始化app-common项目中的云客服
    window.api.sendEvent({ name: 'initAlipush', extra: {} })
  }
  window.isLazyInit = true
}

export function lazyInitMap() {
  if (window.$tool && window.$tool.map && window.$tool.webMap) {
    console.log('map 已经初始化!')
    return
  }
  window.$tool.webMap = new WebMap()
  if ('H5' === localStorage.getItem('MAP_POSITIONING') && typeof window.api === 'undefined') {
    console.log('强制地图使用h5')
    window.$tool.map = window.$tool.webMap
  } else {
    console.log('地图自动适配')
    window.$tool.map = Map()
  }
}
export default async function () {
  window.gcoord = gcoord
  //公共组件图标
  const iconCdn = 'https://static.service.z-trip.cn/app/ztrip/iconfont-app-wxm0419/'
  $tool.loadScript(iconCdn + 'iconfont.js', null, 'wxm-font-js')
  $tool.loadCSS(iconCdn + 'iconfont.css', null, 'wxm-font-css')
  $tool.loadScript(
    'https://static.service.z-trip.cn/resources/icon/1285958847852_font_1267047_594sffii1as/iconfont.js',
    null,
    'common-font-js-2'
  )
  $tool.loadCSS(
    'https://static.service.z-trip.cn/resources/icon/1285958847852_font_1267047_594sffii1as/iconfont.css',
    'common-font-css-2'
  )

  await $ZConfig.initial()
  // 如果 I18N_ENABLE 状态为 false 时则强制关闭国际化能力
  if ($ZConfig.getVal('I18N_ENABLE') !== true) {
    localStorage.setItem('WEB_LOCALE_LANGUAGE', 'zh_CN')
  }

  // zaxios()
  if (!$jump.env.app) {
    await new Promise((resolve) => {
      loadEnvJS(resolve)
    })
  }

  // 非pc模式不记载app工具js
  if (!$tool.isPC()) {
    fixInputLayout()
  }
  if (window.api && typeof window.flutter_inappwebview === 'undefined') {
    window.api.addEventListener(
      {
        name: 'viewappear'
      },
      function () {
        if (location.pathname == '/common/pay') {
          window.$ZCommonPayCallback && window.$ZCommonPayCallback()
        }
      }
    )
    window.api.addEventListener(
      {
        name: 'offline'
      },
      function () {
        api.toast({
          msg: $t('xin-hao-bu-wen-ding'),
          duration: 2000,
          location: 'bottom'
        })
      }
    )
  }
  // 云客服初始化
  console.log('MAIL_SEND_SWITCH', $ZConfig.getVal('MAIL_SEND_SWITCH'))
  CS.initDom()
  window.$CS = CS
  // window.$chat = Chat
  if (!isHideOtherDomain()) {
    window.lazyInit = lazyInit
    window.lazyInitMap = lazyInitMap
  }
}
