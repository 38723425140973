class Location_app {
  getBMap() {
    return window.api.require('bMap')
  }
  getCurrentLocation(callback) {
    let bMap = this.getBMap()
    console.log('加载中。。。')
    bMap.getLocationServices((ret) => {
      if (!ret.enable) {
        // alert('手机定位服务未开启')
        callback({})
      } else {
        bMap.getLocation(
          {
            accuracy: '100m',
            autoStop: true,
            filter: 1
          },
          function (ret, err) {
            if (ret && ret.status) {
              const data = {
                lon: ret.lon,
                lat: ret.lat
              }
              callback(data)
            } else {
              callback(err.code)
            }
          }
        )
      }
    })
  }

  getPermission(callback) {
    return new Promise((resolve, reject) => {
      const permission = 'location'
      let resultList = window.api.hasPermission({
        list: [permission]
      })
      if (resultList[0].granted) {
        resolve()
      } else {
        window.api.confirm(
          {
            msg: '应用需要您的授权才能访问位置信息',
            buttons: ['取消', '去设置']
          },
          function (ret) {
            if (ret.buttonIndex == 2) {
              window.api.requestPermission(
                {
                  list: [permission]
                },
                (res) => {
                  if (res.list.length > 0) {
                    // alert('重新获取位置')
                    let result = window.api.hasPermission({
                      list: [permission]
                    })
                    if (result[0].granted) {
                      resolve()
                    } else {
                      callback({})
                      reject()
                    }
                  } else {
                    callback({})
                    reject()
                  }
                }
              )
            } else {
              callback({})
              reject()
            }
          }
        )
      }
    })
  }

  // getPermission222222() {
  //   return new Promise((resolve, reject) => {
  //     // 查询指定的功能是否开启了权限
  //     const permissions = ['location']
  //     let resultList = api.hasPermission({ list:permissions });
  //     // 获取未授权限的功能
  //     let notPermission = resultList.filter(item => !item.granted)
  //     this.isGetPermission = notPermission.length > 0
  //     if(notPermission.length) {
  //       // 弹窗提示向用户获取权限
  //       api.confirm({
  //         msg: '应用需要您的授权才能访问位置信息',
  //         buttons: ['取消', '去设置']
  //       }, function(ret) {
  //         if (ret.buttonIndex == 2) {
  //           api.requestPermission({
  //             list: notPermission.map(item => item.name)
  //           }, (ret, err) => {
  //             if(ret.list.find(item => item.name === 'location').granted) {
  //               resolve()
  //             } else {
  //               reject()
  //             }
  //           })
  //         }
  //         resolve()
  //       });
  //     } else {
  //       resolve()
  //     }
  //   })
  // }
  getAddress(callback) {
    window.mconsole &&
      window.mconsole.upYueYing2(
        '定位-apicloud',
        {
          ua: navigator.userAgent.toLowerCase(),
          userId: localStorage.getItem('userId')
        },
        199,
        0
      )
    let bMap = this.getBMap()
    if (!bMap) callback({})
    console.log('开始获取地理位置...')
    this.getPermission(callback).then(
      () => {
        if (api.systemType === 'ios') {
          bMap.initMapSDK((ret) => {
            if (ret && ret.status) {
              this.getAddress1(callback)
            }
          })
        } else {
          this.getAddress1(callback)
        }
      },
      (err) => {
        console.log('err', err)
      }
    )
  }
  getAddress1(callback) {
    let bMap = this.getBMap()
    this.getCurrentLocation((lnglat) => {
      bMap.getNameFromCoords(
        {
          lon: lnglat.lon,
          lat: lnglat.lat
        },
        (ret, err) => {
          if (ret && ret.status) {
            const transPoint = gcoord.transform([ret.lon, ret.lat], gcoord.BD09, gcoord.GCJ02)
            callback({
              province: ret.province,
              city: ret.city || ret.district,
              district: ret.district,
              lon: transPoint[0],
              lat: transPoint[1],
              address: ret.address
            })
          } else {
            callback(err)
          }
        }
      )
    })
  }
}
export default Location_app
