/*
 * @Author: youthscc(jiangsong)
 * @Date: 2024-04-11 11:23:56
 * @LastEditors: youthscc(蒋松)
 * @LastEditTime: 2024-06-21 18:15:05
 */
import Location_app from './map.app.js'
import Location_wx from './map.wx.js'
import Location_dd from './map.dd.js'
import Location_web from './map.web.js'
import Location_feishu from './map.feishu'
// import Location_ip from './map.ip.js'
import Location_workwx from './map.workwx.js'
import Location_flutter from './map.flutter'
import Location_flutter_v2 from './map.flutter.v2.js'
import { getZtCoreVersion } from '../libs'
import Location_yy from './map.yy.js'
window.initUrl = location.href
export default function () {
  if ($jump.env.dd) {
    // 钉钉环境
    console.log('Location_dd 地图初始化')
    // return new Location_web()
    return new Location_dd()
  } else if (window.flutter_inappwebview) {
    // flutter
    // 如果个人设置强制开启web定位,则走web方式
    let isflutterH5 = localStorage.getItem('FLUTTER_MAP_H5')
    if (isflutterH5) {
      console.log('flutter FLUTTER_MAP_H5地图初始化')
      return new Location_web()
    } else {
      console.log('flutter 地图初始化')
      // 判断是否是ztcore版本
      if (getZtCoreVersion()) {
        return new Location_flutter_v2()
      }
      return new Location_flutter()
    }
  } else if ($jump.env.miniprogram) {
    // 小程序中使用ip定位
    console.log('小程序中 Location_web 地图初始化')
    // return new Location_ip()
    return new Location_web()
  } else if ($jump.env.weixin) {
    // 微信
    console.log('wx 地图初始化')
    return new Location_wx()
  } else if ($jump.env.wxwork) {
    console.log('Location_feishu 地图初始化')
    return new Location_workwx()
    // return new Location_web()
  } else if ($jump.env.feishu) {
    console.log('Location_feishu 地图初始化')
    return new Location_feishu()
  } else if ($jump.env.yy) {
    // 用友
    console.log('Location_yy 地图初始化')
    return new Location_yy()
  } else if (window.api && !window.api.statusBarAppearance && api.systemType === 'ios') {
    console.log('web11 地图初始化')
    return new Location_web()
  } else if (window.api) {
    // app
    console.log('Location_app 地图初始化')
    return new Location_app()
  } else {
    // web
    console.log('Location_web 地图初始化 ')
    return new Location_web()
  }
}
