import Api from './Base'
import { $t } from './i18n'

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const externalCode = urlParams.get("externalCode");
const tenantCode = urlParams.get("tenantCode");
const channelType = urlParams.get("channelType");
if (externalCode) {
  localStorage.setItem("externalCode", externalCode);
}
if (tenantCode) {
  localStorage.setItem("tenantCode", tenantCode);
}
if (channelType) {
  localStorage.setItem("channelType", channelType);
}
function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return null
}
async function loginByAlp(alp) {
  // 步骤一: 登录中心登录服务器
  let resPassport = await Api.get(
    '/jsonp/login2.json',
    {
      loginType: 'alp',
      alp,
      service: $ZConfig.getServerUrl()
    },
    { baseURL: $ZConfig.getPassportUrl(), timeout: 20000 }
  )
  // alert('loginByAlp'+location.href);
  console.log('appgateway ----- 登录中心登录服务器', resPassport)

  if (resPassport.code != '0') {
    console.error(resPassport.msg)
    alert(resPassport.msg)
    return resPassport
  }

  if (resPassport.jwt) {
    localStorage.setItem('token', resPassport.jwt)
    localStorage.setItem('refreshToken', resPassport.refreshToken)
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler(
        'refreshAppToken',
        JSON.stringify({
          token: resPassport.jwt,
          refreshToken: resPassport.refreshToken
        })
      )
    }
  }
  if (resPassport.userId) {
    localStorage.setItem('userId', resPassport.userId)
  }
  if (resPassport.openId) {
    localStorage.setItem('openId', resPassport.openId)
  }
  // 步骤二: 注入应用服务器
  // let resClient = await Api.get('login.json?KI4SO_CLIENT_EC=' + resPassport.clientKey, {timeout: 12000})
  // if (resClient.code != '0') {
  //   return resClient
  // }
  let curServerUrl = $ZConfig.getServerUrl()
  // 步骤三: 重新加载配置信息
  let resConfig = await new Promise(function (resolve) {
    $ZConfig.reload(resolve)
  })
  // 服务域名与企业所在的域名不一致，无法提供后续服务
  if (curServerUrl != $ZConfig.getServerUrl()) {
    alert($t('dang-qian-deng-lu-yong-hu-suo-'))
    return { code: '100010', msg: $t('gai-zhang-hao-suo-zai-de-qi-ye') }
  }
  return resConfig
}
export default async function () {
  let res = {}
  let alp = getQueryVariable('alp')
  let simple = getQueryVariable('simple')
  let openId = getQueryVariable('openId')
  if (openId) {
    localStorage.setItem('openId', openId)
  }
  // 应该加到session会话中
  if (simple) {
    sessionStorage.setItem('simple', '1')
  }
  if (alp) {
    let resLogin = await loginByAlp(alp)
    console.log('resLogin', resLogin)
    let reloadUrl = location.pathname + location.search.replace('alp=' + alp, '')
    history.replaceState(null, $ZConfig.getVal('NAME'), reloadUrl)
    if (resLogin.code == '0') {
      res.login = true
      // 单业务线模式，无需返回到首页
      if (simple && !location.pathname.startsWith('/home')) {
        sessionStorage.setItem('alpUrl', reloadUrl)
      }
    }
  }
  return Promise.resolve(res)
}
