const ua = window.navigator.userAgent.toLowerCase()
let os = '' //获取系统
if (/(Android)/i.test(navigator.userAgent)) {
  os = 'AndroidOS'
} else if (/(iPhone|iPod|iPad)/.test(navigator.userAgent)) {
  os = 'iOS'
}
const mobile = true
const os_detail = ua.substring(ua.indexOf('(') + 1, ua.indexOf(')'))

const goNoSuport = function () {
  window.location.href = '/nosuport.html'
}

export function checkUserMobile() {
  // 如果用户手机版本 ios <11 , andriod <6 则提示版本太低 ;
  window['mobile_os'] = os
  if (os === 'iOS') {
    const versionMatch = navigator.userAgent.match(/OS (\d+)/)
    if (versionMatch) {
      const version = Number(versionMatch[1])
      if (version <= 10) {
        goNoSuport()
        return false
      }
    }
  } else if (os === 'AndroidOS') {
    const versionMatch = navigator.userAgent.match(/Android (\d+)/)
    if (versionMatch) {
      const version = Number(versionMatch[1])
      if (version <= 6) {
        goNoSuport()
        return false
      }
    }
  }
  return true
}

export function device() {
  const device = {
    entry: '',
    env: {}
  }
  if (mobile) {
    if (ua.includes('apicloud') || window.location.search.indexOf('from=app') !== -1) {
      device.entry = 'app'
    } else if (ua.includes('dingtalk')) {
      device.entry = 'dd'
    } else if (ua.includes('micromessenger') && !ua.includes('miniprogram') && !ua.includes('wxwork')) {
      device.entry = 'wx'
    } else if (ua.includes('wxwork') && !ua.includes('miniprogram')) {
      device.entry = 'wxwork'
    } else if (ua.includes('micromessenger') && ua.includes('miniprogram')) {
      device.entry = 'wx_miniProgram'
    } else if (ua.includes('lark/')) {
      device.entry = 'feishu'
    } else if (ua.includes('mobile') && ua.includes('safari')) {
      device.entry = 'H5_browser'
    } else if (ua.includes('mobile')) {
      device.entry = 'H5_webview'
    } else {
      device.entry = 'PC_browser'
    }
  } else {
    device.entry = 'PC_browser'
  }
  device['Browser'] = ''
  device['H5_browser_version'] = device['Browser'] + '_'

  // app
  if (device.entry === 'app') {
    if (!window.api) {
      console.error('window.api is null')
      // return
    }
    device['App_version'] = window.api.appVersion
    device['App_deviceModel'] = window.api.systemType + '--' + window.api.deviceModel
    device['App_deviceId'] = window.api.deviceId
    device['App_netWorkType'] = window.api.connectionType
    device['App_screen'] = window.api.screenWidth + '*' + window.api.screenHeight
    device['App_operator'] = window.api.operator
    device['App_systemVersion'] = window.api.systemType + '--' + window.api.systemVersion
  } else {
    device['H5_os'] = os
    device['H5_os_detail'] = os_detail
    device['H5_mobile'] = mobile
  }
  const ENV = {
    app: device.entry === 'app',
    dd: device.entry === 'dd',
    wx: device.entry === 'wx',
    wxwork: device.entry === 'wxwork',
    wx_miniProgram: device.entry === 'wx_miniProgram',
    feishu: device.entry === 'feishu',
    other: device.entry === 'H5_browser' || device.entry === 'H5_webview' || device.entry === 'PC_browser'
  }
  device.env = ENV
  return device
}
