import { $t } from '../i18n'
const getStatus = async () => {
  const data = await window.$httpUtil.get('/api/platform/map/location/config')
  if (data && data.data) {
    return data.data
  }
  return false
}

const toggle = () => {
  document.querySelector('.global_position_location_tips').className = 'global_position_location_tips active'
  setTimeout(() => {
    document.querySelector('.global_position_location_tips').className = 'global_position_location_tips'
  }, 5000)
}
export const showLocationTips = async () => {
  const status = await getStatus()
  if (status) {
    setTimeout(() => {
      let el = document.getElementById('location-toast')
      if (el) {
        el.innerHTML = `
          <div class="global_position_location_tips">
          <div class="tips-text">
            <svg
              style="flex-shrink: 0"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1.875C8.11033 1.875 6.29806 2.62567 4.96186 3.96186C3.62567 5.29806 2.875 7.11033 2.875 9C2.875 11.57 5.026 14.61 9.273 18.206C9.693 18.561 10.308 18.561 10.727 18.206C14.974 14.61 17.125 11.57 17.125 9C17.125 7.11033 16.3743 5.29806 15.0381 3.96186C13.7019 2.62567 11.8897 1.875 10 1.875ZM10.064 17.24C10.0681 17.2421 10.0721 17.2444 10.076 17.247L10.064 17.24ZM4.125 9C4.125 7.44185 4.74397 5.94752 5.84575 4.84575C6.94752 3.74397 8.44185 3.125 10 3.125C11.5581 3.125 13.0525 3.74397 14.1543 4.84575C15.256 5.94752 15.875 7.44185 15.875 9C15.875 11.081 13.935 13.837 10 17.183C6.065 13.837 4.125 11.081 4.125 9Z"
                fill="var(--main-color)"
              />
              <path
                d="M10 5.875C9.58962 5.875 9.18326 5.95583 8.80411 6.11288C8.42497 6.26992 8.08047 6.50011 7.79029 6.79029C7.50011 7.08047 7.26992 7.42497 7.11288 7.80411C6.95583 8.18326 6.875 8.58962 6.875 9C6.875 9.41038 6.95583 9.81674 7.11288 10.1959C7.26992 10.575 7.50011 10.9195 7.79029 11.2097C8.08047 11.4999 8.42497 11.7301 8.80411 11.8871C9.18326 12.0442 9.58962 12.125 10 12.125C10.8288 12.125 11.6237 11.7958 12.2097 11.2097C12.7958 10.6237 13.125 9.8288 13.125 9C13.125 8.1712 12.7958 7.37634 12.2097 6.79029C11.6237 6.20424 10.8288 5.875 10 5.875ZM10 7.125C10.2462 7.125 10.49 7.1735 10.7175 7.26773C10.945 7.36195 11.1517 7.50007 11.3258 7.67418C11.4999 7.84828 11.638 8.05498 11.7323 8.28247C11.8265 8.50995 11.875 8.75377 11.875 9C11.875 9.24623 11.8265 9.49005 11.7323 9.71753C11.638 9.94502 11.4999 10.1517 11.3258 10.3258C11.1517 10.4999 10.945 10.638 10.7175 10.7323C10.49 10.8265 10.2462 10.875 10 10.875C9.50272 10.875 9.02581 10.6775 8.67417 10.3258C8.32254 9.97419 8.125 9.49728 8.125 9C8.125 8.50272 8.32254 8.02581 8.67417 7.67418C9.02581 7.32254 9.50272 7.125 10 7.125Z"
                fill="var(--main-color)"
              />
            </svg>
            ${$t('kai-qi-ding-wei-yong-yu-huo-qu')}
          </div>
         </div>`
      }
      toggle()
    }, 500)
  }
}
