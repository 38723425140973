// import axios from 'axios'

import config from './map.conf'
import Web from './map.web'
import { trace } from '../trace'
import { $t } from '../i18n'
class Location_dd {
  getAddress(callback) {
    trace.log('location-msg', {
      from: 'dd',
      msg: '开始定位',
      data: sessionStorage.getItem('dd_corp') || localStorage.getItem('dstore_corpId')
    })
    let corpCode = sessionStorage.getItem('dd_corp') || localStorage.getItem('dstore_corpId')
    if (!corpCode) {
      trace.log('location-msg', {
        from: 'dd',
        msg: '未获取corpId-web定位兜底'
      })
      new Web().getAddress(callback)
      // callback({code: -1, msg: '钉钉编码'})
      return
    }
    window.mconsole &&
      window.mconsole.upYueYing2(
        '定位-dd',
        {
          ua: navigator.userAgent.toLowerCase(),
          userId: localStorage.getItem('userId')
        },
        199,
        0
      )
    trace.log('location-msg', {
      from: 'dd',
      msg: '钉钉定位授权'
    })
    window.$httpUtil.instance
      .get('/auth/getAuthConfig.json', {
        params: {
          corp: corpCode,
          url:
            window.$tool && window.$tool.isIosOrAndroid() === 'android'
              ? window.initUrl
              : decodeURIComponent(location.href.split('#')[0]),
          client: 'ding'
        }
      })
      .then((res) => {
        trace.log('location-msg', {
          from: 'dd',
          msg: '钉钉定位授权结束',
          data: res
        })
        let rs = res.data
        let data = rs.data
        if (rs.code != '0' || !data || !data.signature) {
          trace.log('location-msg', {
            from: 'dd',
            msg: '无法取得鉴权信息 -1',
            data: res
          })
          callback({ code: -1, msg: $t('无法获得鉴权信息') })
          return
        }
        window.dd.error(function (err) {
          trace.log('location-msg', {
            from: 'dd',
            msg: '钉钉鉴权失败，无法获得当前位置信息 -1',
            data: res
          })
          console.error('dd error: ' + JSON.stringify(err))
          callback({ code: -1, msg: $t('ding-ding-jian-quan-shi-bai-wu') })
        })
        // 钉钉鉴权,获取钉钉配置信息
        window.dd.config({
          agentId: data.agentId,
          corpId: data.corpId,
          timeStamp: data.timeStamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          type: 0,
          jsApiList: ['device.geolocation.get']
        })
        window.dd &&
          window.dd.ready(function () {
            window.dd.device.geolocation.get({
              targetAccuracy: config.ACCURACY,
              coordinate: 1,
              withReGeocode: true,
              useCache: false, // 默认是true，如果需要频繁获取地理位置，请设置false
              onSuccess: function (ret) {
                trace.log('location-msg', {
                  from: 'dd',
                  msg: '定位成功',
                  data: ret
                })
                if (ret && ret.longitude != 0 && ret.latitude != 0) {
                  callback({
                    province: ret.province,
                    city: ret.city || ret.district,
                    district: ret.district,
                    address: ret.address,
                    lon: ret.longitude,
                    lat: ret.latitude
                  })
                } else {
                  trace.log('location-msg', {
                    from: 'dd',
                    msg: '定位失败-钉钉内部高德SDK异常',
                    data: ret
                  })
                  callback({ code: -2, msg: $t('WLYC3') })
                }

                /* 高德坐标 result 结构
            {
                longitude : Number,
                latitude : Number,
                accuracy : Number,
                address : String,
                province : String,
                city : String,
                district : String,
                road : String,
                netType : String,
                operatorType : String,
                errorMessage : String,
                errorCode : Number,
                isWifiEnabled : Boolean,
                isGpsEnabled : Boolean,
                isFromMock : Boolean,
                provider : wifi|lbs|gps,
                accuracy : Number,
                isMobileEnabled : Boolean
            }
            */
              },
              onFail: function (err) {
                trace.log('location-msg', {
                  from: 'dd',
                  msg: '定位失败-网络异常，无法获取当前位置 -2',
                  data: err
                })
                callback({ code: -2, msg: $t('WLYC3') })
              }
            })
          })
      })
      .catch((err) => {
        console.error(err)
        trace.log('location-msg', {
          from: 'dd',
          msg: '定位失败-网络异常，无法获得钉钉鉴权信息 -1',
          data: err
        })
        callback({ code: -1, msg: '无法获得钉钉鉴权信息' })
      })
  }
}
export default Location_dd
