export const trace = {
  log(e, data) {
    if (window._ztrace) {
      window._ztrace.log(e, { data })
    } else {
      console.warn('logger未载入')
    }
  },
  root(e, data) {
    if (window._ztrace) {
      window._ztrace.log(e, data)
    } else {
      console.warn('logger未载入')
    }
  },
  tag(nodeCode = '', data = {}) {
    if (window._ztrace && window._ztrace.tag) {
      window._ztrace.tag(nodeCode, data)
    }
  },
  // 页面资源获取行为捕获
  getResources(project) {
    if (!window._ztrace) return
    window._ztrace.updateConfig({ project })
    if (performance === undefined) {
      return
    }
    const resources = performance.getEntriesByType('resource') || []
    let val = resources.map((resource) => {
      return {
        type: resource.initiatorType,
        url: resource.name,
        status: resource.responseStatus,
        dns: (resource.domainLookupEnd - resource.domainLookupStart).toFixed(0),
        fullTime: (resource.startTime > 0 ? resource.responseEnd - resource.startTime : 0).toFixed(0),
        project: project
      }
    })
    window._ztrace.log('resource', {
      data: val,
      raw: performance.getEntries()
    })
    performance.clearResourceTimings()
  }
}

export const traceRequestInterceptor = (config) => {
  if (!window._ztrace) Promise.resolve(config)
  const requestId = window._ztrace.getUUID()
  config.headers['requestId'] = requestId
  config.headers['sessionId'] = window._ztrace.getSessionId()
  const data = {
    path: config.url,
    method: (config.method || '').toUpperCase(),
    requestId
  }
  if (config.headers) {
    if (config.headers.bizUUID) data.bizUUID = config.headers.bizUUID
    if (config.headers.chainCode) data.chainCode = config.headers.chainCode
  }
  window._ztrace.log('request', data)

  return Promise.resolve(config)
}
export const traceResponseInterceptor = (config) => {
  return Promise.resolve(config)
}
