import gcoord from 'gcoord'
import webMap from './map.web'
import { $t } from '../i18n'
// import { trace } from '../trace.js'
// gcoord.WGS84 	[lng,lat] 	WGS-84坐标系，GPS设备获取的经纬度坐标
// gcoord.GCJ02 	[lng,lat] 	GCJ-02坐标系，google中国地图、soso地图、aliyun地图、mapabc地图和高德地图所用的经纬度坐标
// gcoord.BD09 	[lng,lat] 	BD-09坐标系，百度地图采用的经纬度坐标
// gcoord.BD09LL 	[lng,lat] 	同BD09
// gcoord.BD09MC 	[x,y] 	BD-09米制坐标，百度地图采用的米制坐标，单位：米
// gcoord.BD09Meter 	[x,y] 	同BD09MC
// gcoord.Baidu 	[lng,lat] 	百度坐标系，BD-09坐标系别名，同BD-09
// gcoord.BMap 	[lng,lat] 	百度地图，BD-09坐标系别名，同BD-09
// gcoord.AMap 	[lng,lat] 	高德地图，同GCJ-02
// gcoord.WebMercator 	[x,y] 	Web Mercator投影，墨卡托投影，同EPSG3857，单位：米
// gcoord.WGS1984 	[lng,lat] 	WGS-84坐标系别名，同WGS-84
// gcoord.EPSG4326 	[lng,lat] 	WGS-84坐标系别名，同WGS-84
// gcoord.EPSG3857 	[x,y] 	Web Mercator投影，同WebMercator，单位：米
// gcoord.EPSG900913 	[x,y] 	Web Mercator投影，同WebMercator，单位：米

function getPositionByBrowerGps(sucCb, failCb, mapType) {
  if (typeof mapType === 'undefined') {
    mapType = 'GCJ02'
  }
  if (navigator.geolocation) {
    // console.log("navigator.geolocation ====1");
    navigator.geolocation.getCurrentPosition(
      (res) => {
        console.log('GPS坐标')
        // GPS坐标转火星坐标
        // console.log(res.coords);
        if (res.coords) {
          let transPoint = gcoord.transform([res.coords.longitude, res.coords.latitude], gcoord.WGS84, mapType)
          // console.log("WGS84坐标转换成坐标系" + mapType, transPoint);
          // console.log(transPoint[0] + ',' + transPoint[1])
          sucCb(
            sucCbData({
              longitude: transPoint[0],
              latitude: transPoint[1],
              mapType: mapType
            })
          )
        } else {
          failCb(failCbData(null, 'res.coords is null'))
        }
      },
      (error) => {
        console.error('navigator.geolocation ----err')
        console.error('code: ' + error.code + '\n' + 'message: ' + error.message + '\n')
        failCb(
          failCbData(
            {
              message: error.message,
              code: error.code
            },
            error.message
          )
        )
      },
      {
        enableHighAccuracy: true,
        timeout: 2500,
        maximumAge: 0
      }
    )
  } else {
    console.log('navigator.geolocation is NUll')
    failCb(failCbData(null, $t('liu-lan-qi-navigatorgeolocatio')))
  }
}

function isCityCenter(lng, lat) {
  let cityArr = [
    '121.48054,31.23593',
    '116.41339,39.91092',
    '113.27143,23.13534',
    '114.06455,22.54846',
    '104.07274,30.57899'
  ]

  let isCentry = false
  for (let i = 0; i < cityArr.length; i++) {
    let sliceArr = cityArr[i].split(',')
    // console.log(sliceArr);
    let lenStr = GetDistance(lng, lat, sliceArr[0], sliceArr[1])
    // console.log("lenStr+" + i, lenStr);
    // 位置小于200米
    if (lenStr < 200) {
      isCentry = true
      break
    }
  }
  console.log('isCityCenter', isCentry)
  return isCentry
}

function Rad(d) {
  return (d * Math.PI) / 180.0 //经纬度转换成三角函数中度分表形式。
}

//计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
function GetDistance(lat1, lng1, lat2, lng2) {
  let radLat1 = Rad(lat1)
  let radLat2 = Rad(lat2)
  let a = radLat1 - radLat2
  let b = Rad(lng1) - Rad(lng2)
  let s =
    2 *
    Math.asin(
      Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2))
    )
  s = s * 6378.137 // 地球半径，千米;
  s = Math.round(s * 10000) / 10000 //输出为公里
  s = Math.round(s * 1000) / 1 //单位修改为米,取整
  //s=s.toFixed(4);
  return s
}

function sucCbData(data) {
  return {
    code: 0,
    data: data
  }
}

function failCbData(err, msg) {
  return {
    code: -1,
    msg: msg || '',
    err: err
  }
}

//position.data.longitude, position.data.latitude
// 这里传入的坐标系为百度坐标系;返回的要求为gcj02

function handlerWithPos(longitude, latitude, callback, coordtype = 'BD09') {
  let data = {}
  data.longitude = longitude
  data.latitude = latitude
  // 坐标统一都是GCJ02坐标系
  // console.log('百度坐标系====data', data)
  // data.type = gcoord.GCJ02.toLocaleLowerCase()
  if (coordtype !== 'BD09') {
    // data.type = gcoord.GCJ02.toLocaleLowerCase()
    console.error('请把坐标转成百度坐标系')
  }
  try {
    Promise.resolve(data)
      .then((data) => webMap.geocoder(data, { coordtype: coordtype, ret_coordtype: 'BD09' }))
      .then((res) => {
        if (res.status === 0) {
          const ret = res.result
          // 这里使用的是百度坐标系,所以需要转成火星坐标系;
          // 返回的坐标,是纠正坐标; 感觉还不如用自身返回的坐标;
          // console.log('----ret'+JSON.stringify(ret))
          // 转换后的ret_coordtype固定位百度坐标系;
          const transPoint = gcoord.transform([ret.location.lng, ret.location.lat], 'BD09', gcoord.GCJ02)
          // console.log('转成火星坐标系输出111'+JSON.stringify(transPoint))
          let paramsMsg = {
            province: ret.addressComponent.province,
            city: ret.addressComponent.city || ret.addressComponent.district,
            district: ret.addressComponent.district,
            address: ret.formatted_address,
            lon: transPoint[0],
            lat: transPoint[1],
            code: '0'
          }
          // console.log('转成火星坐标系输出222'+JSON.stringify(paramsMsg))
          callback(paramsMsg)
        } else {
          callback({ code: '-1', msg: $t('geocoder-zhuan-huan-fail') })
          // 第三方私有化部署,不要忘记配置百度地图域名白名单!!!
          console.error('geocoder 转换 fail!')
        }
      })
  } catch (err) {
    console.error(err)
    // 关于状态码
    callback({ code: '-1', msg: 'webMap.geocoder fail 2' })
  }
}

function mapReadyCb(sucCb, failCb) {
  let geolocation = window.geolocation
  if (!geolocation) {
    geolocation = new BMap.Geolocation()
    window.geolocation = geolocation
  }

  geolocation.enableSDKLocation()
  geolocation.getCurrentPosition(
    function (r) {
      console.log('百度地图结束定位时间戳' + new Date().getTime())
      console.log('r =' + JSON.stringify(r), r)
      if (this.getStatus() == BMAP_STATUS_SUCCESS) {
        // console.log(
        //     "百度地图原始获取坐标:",
        //     JSON.stringify([r.point.lng, r.point.lat])
        // );
        // let transPoint = gcoord.transform(
        //     [r.point.lng, r.point.lat],
        //     gcoord.BD09LL,
        //     mapType
        // );
        // console.log(gcoord.BD09 + "坐标转换成" + mapType + "坐标系", transPoint);
        // console.log(transPoint[0] + "," + transPoint[1]);
        if (isCityCenter(r.point.lng, r.point.lat)) {
          failCb(failCbData(null, $t('ding-wei-shi-bai')))
        } else {
          sucCb(
            sucCbData({
              longitude: r.point.lng,
              latitude: r.point.lat
            })
          )
        }
      } else {
        console.log('failed' + this.getStatus())
        failCb(failCbData({ status: this.getStatus() }, 'geolocation.getCurrentPosition failed ' + this.getStatus()))
      }
    },
    function (err) {
      console.error(err)
      failCb(failCbData(err, ' geolocation.getCurrentPosition catch'))
    },
    {
      enableHighAccuracy: true, //是否要求高精度的地理位置信息
      timeout: 5000, //对地理位置信息的获取操作做超时限制
      maximumAge: 0 //设置缓存有效时间
    }
  )
}
function getPositionByBd(sucCb, failCb) {
  if (window.BMap) {
    console.log('window.BMap is Ready....')
    mapReadyCb(sucCb, failCb)
  } else {
    alert('window.BMap is not Ready....')
  }
}

export {
  getPositionByBrowerGps,
  getPositionByBd,
  // getPositionByGd,
  isCityCenter,
  handlerWithPos
}
