import zh from '../lang/zh_CN.json'
import en from '../lang/en_US.json'
export const i18nRequestInterceptor = (config) => {
  if (sessionStorage.getItem('WEB_LOCALE_LANGUAGE') || localStorage.getItem('WEB_LOCALE_LANGUAGE')) {
    config.headers['Accept-Language'] = (
      sessionStorage.getItem('WEB_LOCALE_LANGUAGE') || localStorage.getItem('WEB_LOCALE_LANGUAGE')
    ).replace(/_/g, '-')
  }
  return Promise.resolve(config)
}

/**
 * 翻译
 * @returns  () => Object
 *  t()
 */
const obj = {}
Object.keys(zh).forEach((item) => {
  Object.defineProperty(obj, item, {
    configurable: true,
    enumerable: true,
    get: function () {
      if (
        sessionStorage.getItem('WEB_LOCALE_LANGUAGE') === 'zh_CN' ||
        !localStorage.getItem('WEB_LOCALE_LANGUAGE') ||
        localStorage.getItem('WEB_LOCALE_LANGUAGE') === 'zh_CN'
      ) {
        return zh[item]
      } else {
        return en[item]
      }
    },
    set: function () {
      return
    }
  })
})
export const $t = function (e) {
  return obj[e] || e
}
