import axios from 'axios'
import { decodeJwtTime } from './libs'
import { trace, traceRequestInterceptor, traceResponseInterceptor } from './trace'
import { i18nRequestInterceptor, $t } from './i18n'

const isLogin =
  _ &&
  _.throttle(() => {
    trace.tag('IS_LOGIN')
    // 检测cookie是否存在,如果不存在跳转
    return checkCookie()
  }, 3000)
const freshToken =
  _ &&
  _.throttle(_freshToken, 3000, {
    leading: true,
    trailing: false
  })

function checkCookie() {
  var zt_verName =
    (window.zzzconfig && window.zzzconfig.getZatuVerName && window.zzzconfig.getZatuVerName()) || 'zaitu-ver'
  console.log('checkCookie', document.cookie.indexOf(zt_verName))
  if (document.cookie.indexOf(zt_verName) == -1) {
    trace.tag('GATEWAY_CHECK_COOKIE_http')
    if (sessionStorage.getItem('COOKIE_LOST')) {
      console.log('已经执行过跳转')
    } else {
      sessionStorage.setItem('COOKIE_LOST', 'true')
      console.error('cookie不存在.....跳转', document.cookie.indexOf(zt_verName))
      window.zzzconfig && window.zzzconfig.goGatePage(location.origin + '/home', true, 'checkCookie_js')
      return true
    }
  }
  return false
}
function goGateRefresh(data, res) {
  trace.tag('COOKIE_FORCERELOAD', {
    data,
    token: localStorage.getItem('token'),
    url: res.config?.url || '',
    res: JSON.stringify(res) || ''
  })
  // localStorage.setItem('H5_COOKIE_INFO', JSON.stringify({ data: data }))
  // flutter跟移动端cookie刷新分开
  window.zzzconfig && window.zzzconfig.goGatePage(location.origin + '/home', true, 'http_js')
}

const unLogin =
  _ &&
  _.throttle(
    () =>
      trace.tag('UN_LOGIN', {
        wpk: {
          category: 207,
          msg: '登录失效'
        }
      }),
    3000
  )

function toH5(path) {
  // path += '&redirect=' + encodeURIComponent(location.pathname + location.search)
  // $jump.replace({path})
  window.location.replace(location.origin + path)
}
function log(object) {
  window.$tool && window.$tool.logger && window.$tool.logger('zconfigNext-1', object)
}
export const get = function (instance, url, params, config) {
  let _configs = {}
  Object.assign(_configs, config)
  let p = {}
  Object.assign(p, params ? { params } : {}, _configs)
  return instance
    .get(url, p)
    .then((res) => {
      return Promise.resolve((res && res.data) || {})
    })
    .catch((err) => {
      console.error(err)
      return Promise.resolve({
        code: '-404',
        msg: err.message || $t('WLYC')
      })
    })
}

export const post = function (instance, url, data, config = {}) {
  // const token = localStorage.getItem('token')
  // if (token && 'undefined' !== token) {
  //     // p.headers['Authorization'] =  'Bearer '+ token
  //     config.headers['Authorization'] = 'Bearer ' + token
  // }
  return instance
    .post(url, data, config)
    .then((res) => {
      return Promise.resolve((res && res.data) || {})
    })
    .catch((err) => {
      console.error(err)
      return Promise.resolve({
        code: '-404',
        msg: err.message || $t('WLYC')
      })
    })
}

export async function _freshToken() {
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')
  try {
    let r = Date.now()
    const res = await axios.get('/jsonp/refreshToken', {
      params: { jwt: token, refreshToken: refreshToken },
      baseURL: window.$ZConfig.getPassportUrl(),
      headers: { Authorization: 'Bearer ' + token },
      withCredentials: true
    })

    if (res && res.data && res.data.code === '0') {
      window.mconsole && window.mconsole.upYueYing($t('freshtoken-zheng-chang'), '', 147, 1)
      window.mconsole &&
        window.mconsole.upYueYingAvg &&
        window.mconsole.upYueYingAvg('freshToken时间', Date.now() - r, 147)
      localStorage.setItem('token', res.data.data)
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler(
          'refreshAppToken',
          JSON.stringify({
            token: res.data.data
          })
        )
      }
      return Promise.resolve({ code: '0', data: res.data.data })
    } else {
      window.mconsole && window.mconsole.upYueYing('freshToken异常', { res: res.data, params: { jwt: token } }, 147, 0)
      // alert(res.data?.msg || 'freshToken失败!')
      console.log('异常111111')
      log({
        type: 'refreshToken-err1',
        data: res && res.data
      })
      exceptToken()
      return Promise.resolve({ code: '-1', msg: $t('freshtoken-shi-bai') })
    }
  } catch (err) {
    console.log('异常3333', err)
    log({
      type: 'refreshToken-err2',
      data: err
    })
    exceptToken()
    return Promise.resolve({ code: '-2', err })
  }
}

function exceptToken() {
  console.error('token无效')
  log({
    type: 'http-exceptToken'
  })
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  if (window.flutter_inappwebview) {
    window.$jump && window.$jump.login({})
  } else {
    location.replace(location.origin + '/login')
  }
}
function isNeedToken(pageUrl) {
  var notNeedTokenPrefix = [
    'jsonp/login2.json',
    'members/register', // 这个前缀,包括后面的了,所以可以注释掉
    'common/static/alipayFail.html',
    'logout.json',
    'logoutEc',
    'getMpOpenId.json',
    'notify/systemUpgrade.json',
    'pay/cmbResult.json',
    'common/static/alipayFail',
    'common/static/alipaySuccess',
    'platform/configAd/loginAd.json',
    'pss/customer/add.json'
  ]
  let isNeedTokenRet = true
  notNeedTokenPrefix.forEach((item) => {
    if (pageUrl.indexOf(item) !== -1) {
      isNeedTokenRet = false
    }
  })
  return isNeedTokenRet
}
export const getHttpInstance = function () {
  if (window.$httpUtil) {
    return window.$httpUtil
  }
  //单利
  const instance = axios.create({
    baseURL: window.$ZConfig.getServerUrl(),
    withCredentials: true
    // timeout: 60000  // 超时的时间限制
  })

  /* eslint-disable no-proto */
  instance.__proto__ = axios
  /* eslint-enable */

  instance.interceptors.request.use(
    (request) => {
      return request
    },
    (error) => {
      if (error.response) {
        console.log('111113333')
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        console.log('22223333')
        console.log(error.request)
      } else {
        console.log('3333333')
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log('========error333', error['message'] == ' Request aborted', error['message'])
      if (error.message == ' Request aborted') {
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )

  instance.interceptors.request.use(
    async function (config) {
      // 在请求发出之前进行一些操作
      // console.log('requestrequest2', config.url)
      const token = localStorage.getItem('token')

      // const refreshToken = localStorage.getItem('refreshToken')
      let sid = sessionStorage.getItem('sid')
      if (typeof config.headers == 'undefined') {
        config.headers = {}
      }

      if (sid) {
        config.headers['x-auth-token'] = sid
      }
      let ismock = localStorage.getItem('ismock')
      if (ismock) {
        config.headers['ismock'] = ismock
      }

      const _url = config.url.charAt(0) == '/' ? config.url.substring(1) : config.url
      // 如果不需要权限的接口,或者是login页面;直接返回
      if (!isNeedToken(_url)) {
        // console.log('过滤掉了_url,不加token', _url)
        return Promise.resolve(config)
      } else {
        if (token) {
          if (isLogin()) return
          config.headers['Authorization'] = 'Bearer ' + token
          // 新增cookie改造字段
          config.headers['Zt-Client'] = 'h5'
          const expTime = decodeJwtTime(token)
          if (expTime <= -1) {
            console.log('异常00000')
            exceptToken()
            config.headers = {}
            return Promise.resolve(config)
          } else if (expTime - 300 * 1000 < new Date().getTime()) {
            const freshRet = await freshToken()
            if (freshRet.code === '0') {
              config.headers['Authorization'] = 'Bearer ' + freshRet.data
              return Promise.resolve(config)
            } else {
              return Promise.resolve(config)
            }
          } else {
            return Promise.resolve(config)
          }
        } else if (_url.indexOf('isLogin.json') !== -1) {
          return Promise.resolve(config)
        } else {
          unLogin()
          try {
            caches.delete('api-cache')
          } catch (e) {
            console.error('Service Worker 删除缓存失败', e)
          }
          if (window.$flutter) {
            window.$flutter.logOut()
          } else {
            toH5('/login')
          }
          return Promise.resolve(config)
        }
      }
    },
    (err) => {
      console.error('instance.interceptors.request catch:--------*******', err)
      return Promise.resolve({
        data: { code: -1, msg: 'instance.interceptors.request catch' }
      })
    }
  )
  instance.interceptors.request.use(traceRequestInterceptor)
  instance.interceptors.request.use(i18nRequestInterceptor)
  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('11111')
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        console.log('2222')
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        console.log('333')
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log('========error', error['message'] == ' Request aborted', error['message'])
      if (error.message == ' Request aborted') {
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(traceResponseInterceptor)
  instance.interceptors.response.use(
    async function (res) {
      // console.log('=========response.config.url', res.config.url)
      // 请求前会判断是否过期,所以这里接口过期,不再处理

      var data = res.data || {}
      if (data.code == '-99' && !res.config.nojump) {
        console.error(res.config.url + '接口-99', data)
        window.mconsole && window.mconsole.upYueYing('接口-99', { info: res.config.url + '接口-99', data }, 154, 0)
      } else if (data.code == '-98' && !res.config.nojump) {
        console.log('需要从新刷新页面', res.config.url, typeof sessionStorage.getItem('H5_COOKIE_INFO'))
        if (!sessionStorage.getItem('H5_COOKIE_INFO')) {
          // goGateFnThrottle()()
          sessionStorage.setItem('H5_COOKIE_INFO', JSON.stringify({ data: data }))
          goGateRefresh(data, res)
        }
        return Promise.resolve({
          data: {
            code: '98',
            msg: $t('XYQZSXYM')
          }
        })
      } else if (data.code == '5400') {
        // freshToken
        console.error(res.config.url + '接口5400', data)
        window.mconsole &&
          window.mconsole.upYueYing(
            '接口5400',
            {
              url: location.href,
              referrer: document.referrer || '',
              info: res.config.url + '接口5400',
              data
            },
            154,
            0
          )
        const freshRet = await freshToken()
        // console.log('======freshRet', freshRet)
        if (freshRet.code === '0') {
          // 刷新后,重新调到首页
          if (typeof window.isFreshTokened == 'undefined') {
            // 避免多次调用
            window.isFreshTokened = true
            location.replace(location.origin + '/')
          }
          // config.headers['Authorization'] = 'Bearer ' + freshRet.data
          return Promise.resolve({
            data: {
              code: '600',
              msg: $t('token-wu-xiao-freshtoken')
            }
          })
        } else {
          return Promise.resolve({
            data: {
              code: '600',
              msg: $t('freshtoken-shua-xin-bao-cuo')
            }
          })
        }
      }
      if (window.mconsole) {
        window.mconsole.apilog(res)
      }
      return Promise.resolve(res)
    },
    (err) => {
      // 出错或正常响应拦截
      console.error('catch err', err)
      // 检查是否为取消请求的错误
      if (axios.isCancel(err)) {
        console.log('请求被取消:', err.message)
        return Promise.reject(err)
      }
      if (typeof err === 'string') {
        return Promise.resolve({
          data: {
            code: '500',
            msg: err
          }
        })
      }
      if (!err.response) {
        err.response = { data: {} }
      }
      return Promise.resolve({
        data: {
          code: err.response?.status || -2,
          msg: err.response.statusText || $t('FWYC') + (err.response.status || '')
        }
      })
    }
  )

  const $http = {
    get: (...args) => {
      return get(instance, ...args)
    },
    post: (...args) => {
      return post(instance, ...args)
    },
    cancelToken: axios.CancelToken,
    instance
  }
  window.$httpUtil = $http
  return $http
}
