import { compareVersions } from '../libs'
import { showLocationTips } from './map.trigger'
import { trace } from '../trace'
import { $t } from '../i18n'

function checkPermisionFromList(key, list) {
  // "list":[{"name":"location","granted":false}]
  let isGranted = false
  let itemFind = list.find((item) => item.name === key)
  // console.log('itemFind isGranted ' + itemFind.granted)
  if (itemFind !== null && itemFind.granted === true) {
    return true
  }
  return isGranted
}
class Location_flutter {
  getPermission() {
    trace.log('location-msg', {
      from: 'flutter',
      msg: '开始授权: getPermission'
    })
    return new Promise((resolve, reject) => {
      if (typeof window.flutter_inappwebview === 'undefined') {
        reject({
          code: '-3',
          msg: $t('fei-flutter-rong-qi')
        })
      }

      if (compareVersions('3.1.0') >= 0) {
        trace.log('location-msg', {
          from: 'flutter',
          msg: '开始Flutter授权: getPermission'
        })
        let retPromise =
          window.flutter_inappwebview &&
          window.flutter_inappwebview.callHandler('getPositionPermission', JSON.stringify({}))
        retPromise
          .then((data) => {
            console.log('getPermission1111111111' + JSON.stringify(data))
            let retJson = data || {}
            if (retJson && retJson.code !== 1) {
              trace.log('location-msg', {
                from: 'flutter',
                msg: '请开启定位权限: getPermission',
                data: retJson
              })
              reject({
                code: '-1',
                msg: retJson.msg || '请开启定位权限'
              })
            } else {
              trace.log('location-msg', {
                from: 'flutter',
                msg: '定位权限已开启: getPermission',
                data: retJson
              })
              resolve({
                code: '0'
              })
            }
          })
          .catch((err) => {
            trace.log('location-msg', {
              from: 'flutter',
              msg: '请确认开启定位权限: getPermission',
              data: err
            })
            reject({
              code: '-2',
              msg: $t('qing-que-ren-kai-qi-ding-wei-q')
            })
          })
      } else {
        trace.log('location-msg', {
          from: 'flutter',
          msg: '开始旧Flutter授权: getPermission'
        })
        console.log('不存在函数getPositionPermission')
        // 后续这个函数等所有app更新后3.1之后要删除掉;
        // 因为这个权限只能检测是否开允许获取位置, 但是无法检测到设置中的位置权限是否打开; 上面的函数是专门针对位置权限做的检测;可以检测位置权限是否打开
        let retPromise =
          window.flutter_inappwebview &&
          window.flutter_inappwebview.callHandler('requestPermission', JSON.stringify({ list: ['location'] }))
        //{"code":1,"list":[{"name":"location","granted":false}]}
        retPromise
          .then((data) => {
            trace.log('location-msg', {
              from: 'flutter',
              msg: '旧Flutter授权结束: getPermission',
              data
            })
            let retJson = data || {}
            if (retJson && retJson.code !== 1) {
              trace.log('location-msg', {
                from: 'flutter',
                msg: '请开启定位权限-旧: getPermission',
                data: retJson
              })
              reject({
                code: '-1',
                msg: retJson.msg || '请开启定位权限'
              })
            } else {
              // console.log('定位权限ok111')
              if (retJson.list && !checkPermisionFromList('location', retJson.list)) {
                trace.log('location-msg', {
                  from: 'flutter',
                  msg: '请开启定位权限-旧2: getPermission',
                  data: retJson
                })
                reject({
                  code: '-1',
                  msg: retJson.msg || '请开启定位权限'
                })
              } else {
                trace.log('location-msg', {
                  from: 'flutter',
                  msg: '定位权限已开启-旧: getPermission',
                  data: retJson
                })
                resolve({
                  code: '0'
                })
              }
            }
          })
          .catch((err) => {
            trace.log('location-msg', {
              from: 'flutter',
              msg: '请确认开启定位权限-旧: getPermission',
              data: err
            })
            reject({
              code: '-2',
              msg: '请确认开启定位权限'
            })
          })
      }
    })
  }

  getAddress(callback) {
    trace.log('location-msg', {
      from: 'flutter',
      msg: '开始定位: getAddress'
    })
    showLocationTips()
    console.log('Flutter ------getAddress')
    this.getPermission()
      .then((data) => {
        if (data.code !== '0') {
          let msgErr = data.msg || $t('mei-you-quan-xian')
          // alert(data.msg)
          trace.log('location-msg', {
            from: 'flutter',
            msg: '没有定位权限 -99: getAddress',
            data: data
          })
          callback({
            code: '-99',
            msg: msgErr
          })
          return
        }
        window.mconsole && window.mconsole.upYueYing2('定位-flutter', {}, 199, 0)
        window.flutterLocaionCb = function (retInfo) {
          const ret = JSON.parse(retInfo) || {}
          if (ret && ret.longitude && ret.latitude) {
            window.mconsole &&
              window.mconsole.endUploadByPage('flutter_map_time', {
                w_succ: 0,
                category: 198,
                msg: 'flutter定位耗时'
              })
            window.mconsole && window.mconsole.upYueYing2('flutter原生地图定位成功', {}, 192, 0)
            trace.log('location-msg', {
              from: 'flutter',
              msg: '定位成功: getAddress',
              data: retInfo
            })
            callback({
              code: 0,
              province: ret.province,
              city: ret.city || ret.district,
              district: ret.district,
              lon: ret.longitude,
              lat: ret.latitude,
              street: ret.street,
              address: ret.address,
              isTransformed: true // 是否精确,为ture,gcoord.transform不需要纠正了; 百度地图已经精准定位,所以不需要纠正了;
            })
          } else {
            console.log('------22')
            window.mconsole && window.mconsole.upYueYing2('flutter原生地图定位失败,使用h5兜底', {}, 192, 1)
            // getAddressByBlow(callback)
            if (window.$tool?.webMap) {
              trace.log('location-msg', {
                from: 'flutter',
                msg: '定位失败-h5兜底: getAddress',
                data: retInfo
              })
              window.$tool.webMap.getAddress(callback)
            } else {
              trace.log('location-msg', {
                from: 'flutter',
                msg: '定位失败-h5兜底失败 $tool.webMap 尚未加载: getAddress',
                data: retInfo
              })
              callback({
                code: -1,
                msg: $t('toolwebmap-shang-wei-jia-zai')
              })
            }
          }
          // 回调之后把回调销毁;
          setTimeout(() => {
            delete window.flutterLocaionCb
          }, 100)
        }
        window.mconsole && window.mconsole.startUploadByPage('flutter_map_time')
        window.$flutter.getLocation('flutterLocaionCb')
      })
      .catch((err) => {
        let msgErr = err.msg || $t('app-wei-zhi-quan-xian-yi-chang')
        console.error(msgErr)
        trace.log('location-msg', {
          from: 'flutter',
          msg: '定位失败-权限异常-99: getAddress',
          data: err
        })
        callback({
          code: '-99',
          msg: msgErr
        })
      })
  }
}
export default Location_flutter
