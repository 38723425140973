/*
 * @Author: youthscc(蒋松)
 * @Date: 2024-04-19 17:14:29
 * @LastEditors: youthscc(蒋松)
 * @LastEditTime: 2024-05-10 16:11:40
 * @Description: 
 */
import { trace } from '../trace'
import { $t } from '../i18n'
import webMap from './map.web'
class Location_yy {
  async getAddress(callback) {
    const mtl = await import("mtl-js-sdk")
    trace.log('location-msg', {
      from: 'yy',
      msg: '用友开始定位'
    })
    console.log('用友开始定位')
    // 用友获取定位经纬度
    mtl.getLocation({
      type: "wgs84",
      success(data) {
        console.log(data, "获取用友经纬位置信息成功")
        trace.log('location-msg', {
          from: 'yy',
          msg: '定位结束: 获取用友经纬位置信息成功',
          data: data
        })
        data.from = 3
        Promise.resolve(data)
          .then(webMap.geoconv)
          .then(webMap.geocoder)
          .then((res) => {
            if (res && res.status === 0) {
              const ret = res.result
              const transPoint = gcoord.transform([ret.location.lng, ret.location.lat], gcoord.BD09, gcoord.GCJ02)
              let paramsMsg = {
                province: ret.addressComponent.province,
                city: ret.addressComponent.city || ret.addressComponent.district,
                district: ret.addressComponent.district,
                address: ret.formatted_address,
                lon: transPoint[0],
                lat: transPoint[1],
                code: '0'
              }
              console.log(paramsMsg, "获取用友城市位置信息成功")
              trace.log('location-msg', {
                from: 'yy',
                msg: '定位成功: 获取用友城市位置信息成功',
                data: paramsMsg
              })
              callback(paramsMsg)
            } else {
              console.log(data, "获取用友经纬位置信息城市翻译API失败")
              trace.log('location-msg', {
                from: 'yy',
                msg: '定位失败: getLocation',
                data: { code: -1, msg: "获取用友经纬位置信息城市翻译API失败" }
              })
              callback({ code: -1, msg: "获取用友经纬位置信息城市翻译API失败" })
            }
          })

      },
      fail(err) {
        // success(err) {
        console.log("用友mtl定位失败,错误信息 --- ", err)
        let message = err.message // 错误信息
        trace.log('location-msg', {
          from: 'yy',
          msg: `用友mtl定位失败`,
          data: `失败原因: ${message}`
        })
        if (window.$tool?.webMap) {
          console.log("用友定位失败-转用h5定位兜底: getAddress")
          trace.log('location-msg', {
            from: 'yy',
            msg: '用友定位失败-转用h5定位兜底: getAddress',
          })
          window.$tool.webMap.getAddress(callback)
        } else {
          console.log("定位失败-h5兜底失败 $tool.webMap 尚未加载: getAddress")
          trace.log('location-msg', {
            from: 'yy',
            msg: '定位失败-h5兜底失败 $tool.webMap 尚未加载: getAddress',
          })
          callback({
            code: -1,
            msg: $t('toolwebmap-shang-wei-jia-zai')
          })
        }

      },
    })
  }
}
export default Location_yy
