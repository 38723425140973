import axios from 'axios'
function isChromeBelow90() {
  let userAgent = navigator.userAgent
  console.log('浏览器版本' + userAgent)
  let chromeMatch = userAgent.match(/Chrome\/(\d+)/) // 匹配 Chrome 版本号
  if (chromeMatch) {
    let chromeVersion = parseInt(chromeMatch[1]) // 提取版本号并转为整数
    console.log('浏览器版本' + chromeVersion)
    return chromeVersion < 90 // 比较版本号是否小于 90
  }

  return false // 如果无法匹配到 Chrome 版本号，则返回 false
}

function isUAT() {
  return (
    window.location.host.indexOf('-uat') != -1 ||
    window.location.host.indexOf('-dev') != -1 ||
    window.location.host.indexOf('localhost') != -1 ||
    window.location.host.indexOf('192.168') != -1
  )
}
function appendUAT(url) {
  if (!url) {
    return null
  }
  // 测试环境下：前缀加-uat。
  if (isUAT() && url.indexOf('-uat') == -1) {
    if (url.indexOf('.ding') != -1) {
      url = url.replace('.ding', '.ding-uat')
    } else if (url.indexOf('.service') != -1) {
      // 例如：aoyuan-payment.service.z-trip.cn
      url = url.replace('.service', '.service-uat')
    } else {
      // 例如：m-uat.z-trip.cn ms-uat.z-trip.cn payment-uat.z-trip.cn
      let i = url.indexOf('.')
      if (i != -1) {
        url = url.substr(0, i) + '-uat' + url.substr(i)
      }
    }
  }
  return url.startsWith('http') ? url : 'https://' + url
}
function getPassportUrl(sucCb) {
  if (window.flutter_inappwebview) {
    sucCb(appendUAT(BASIC_CONF.SYS.DOMAIN_PASSPORT))
    return
  }
  httpGet(
    '/common/config',
    {},
    function (data) {
      let retData = data.data || {}
      if (retData && retData['DOMAIN_PASSPORT']) {
        sucCb(appendUAT(retData['DOMAIN_PASSPORT']))
      } else {
        sucCb(appendUAT(BASIC_CONF.SYS.DOMAIN_PASSPORT))
      }
    },
    function (err) {
      console.error(err)
      sucCb(appendUAT(BASIC_CONF.SYS.DOMAIN_PASSPORT))
    }
  )
}
// getPassportUrl转异步
function getPassportUrlAsync() {
  return new Promise((resolve) => getPassportUrl((e) => resolve(e)))
}

//清空cookie
function clearCookie() {
  // eslint-disable-next-line no-useless-escape
  let keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  let prefixDomain = getDomainPath()
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() //清除当前域名下的,例如：m.kevis.com
      document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() //清除当前域名下的，例如 .m.kevis.com
      document.cookie = keys[i] + '=0;path=/;domain=' + prefixDomain + ';expires=' + new Date(0).toUTCString() //清除一级域名下的或指定的，例如 .kevis.com
    }
  }

  console.log('cookie已清除')
}
function clearAllEnv() {
  clearCookie()
  // const TRACK_DATA = sessionStorage.getItem('TRACK_DATA')
  // const WEB_SESSION_ID = sessionStorage.getItem('WEB_SESSION_ID')
  // sessionStorage.clear()
  // sessionStorage.setItem('TRACK_DATA', TRACK_DATA)
  // sessionStorage.setItem('WEB_SESSION_ID', WEB_SESSION_ID)
  // localStorage.clear()
}

function httpGetAxios(url, params, headers) {
  return new Promise((reslove, reject) => {
    if (url.indexOf('http') == -1) {
      url = getBaseUrl() + (url.indexOf('/') == 0 ? url : '/' + url)
    }
    console.log('httpGetAxios Url', url)
    axios
      .get(url, {
        params: params,
        headers: headers || {},
        timeout: 20000,
        crossDomain: true,
        withCredentials: true
        // withCredentials: false
      })
      .then(function (data) {
        console.log('httpGetAxios_data', JSON.stringify(data))
        reslove(data.data || {})
      })
      .catch(function (err) {
        console.log('httpGetAxios_catch', JSON.stringify(err))
        slsLog({
          data: {
            name: 'httpGetAxios_catch',
            url,
            err
          }
        })
        reject({
          msg: err.message || url + '接口异常'
        })
      })
  })
}
function httpGet(url, params, sucCb, failCb, headers) {
  if (typeof window.$ == 'undefined') {
    failCb('请先引入jquery')
    return
  }
  if (url.indexOf('http') == -1) {
    url = getBaseUrl() + (url.indexOf('/') == 0 ? url : '/' + url)
  }

  axios
    .get(url, {
      params: params,
      headers: headers || {}
      // withCredentials: false
    })
    .then(function (data) {
      sucCb(data.data)
    })
    .catch(function (err) {
      failCb(err)
    })
}

function getQueryString(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  console.log(window.location, name)
  if (window.location.search == '') {
    if (window.location.hash.indexOf('?') == -1) {
      console.error('没有参数')
      return null
    }
    let r = window.location.hash.substring(window.location.hash.indexOf('?') + 1).match(reg)
    console.log('====1', r)
    if (r != null) {
      return decodeURIComponent(r[2])
    }
  } else {
    let r = window.location.search.substring(1).match(reg)
    console.log('====2', r)
    if (r != null) {
      return decodeURIComponent(r[2])
    }
  }

  return null
}

function getBaseUrl() {
  let configurl = null
  console.log('getBaseUrl', window.zzzconfig)
  if (window.zzzconfig) {
    for (let key in window.zzzconfig.server) {
      if (location.host.indexOf(key) != -1) {
        configurl = window.zzzconfig.server[key]
        break
      }
    }
  }
  console.log('1111', configurl)
  if (configurl) {
    return configurl
  } else if (
    window.location.host.indexOf('-uat') !== -1 ||
    window.location.host.indexOf('localhost') !== -1 ||
    window.location.host.indexOf('-dev') !== -1 ||
    window.location.host.indexOf('192.168') !== -1
  ) {
    return 'https://ms-uat.z-trip.cn'
  } else if (window.location.host.indexOf('-test') !== -1) {
    return 'https://ms-test.z-trip.cn'
  } else {
    return 'https://' + BASIC_CONF.SYS.DOMAIN_MS
  }
}

function toErrorPage(key) {
  console.log('toErrorPage', key)
}

function getRouteData(res, url) {
  let data = res.data || {}
  let bizType = data._bizType_
  let routerData = null
  // var mockData =  {"canDeal":true,"processLogList":[{"autoMerge":0,"endDate":1686555639000,"executing":false,"executor":38222,"executorName":"戴恋冬","pdNodeName":"发起申请","routeCode":"O2tOHEJ4QsyXBcypfYZ4WQ","routeName":"提交审核"},{"executing":true,"executorIds":"38222","executorName":"戴恋冬","pdNodeName":"审批"},{"executing":false,"executorIds":"38222","executorName":"戴恋冬","pdNodeName":"出纳付款"}],
  // "_bizId_":"28350","_taskId_":13209,"_bizType_":"EC_ER_FORM"}

  if (bizType) {
    switch (bizType) {
      case 'PAY_CORP_PAYMENT_FORM': // 公司支付
        routerData = {
          name: 'applyPayDetail',
          query: {
            id: data._bizId_,
            taskId: data._taskId_
          }
        }
        break
      case 'OTAM_TRAVEL_PLAN': // 差旅计划
        sessionStorage.setItem('ta_approval', JSON.stringify(data))
        sessionStorage.setItem('ta_url', url)
        routerData = {
          name: 'travelapproval',
          query: {
            // approval: JSON.stringify(data),
            // url2:url
          }
        }
        break
      case 'paySuccess': // 差旅申请
        switch (res.state) {
          case '99':
          case '0':
            routerData = {
              name: 'paySuccess',
              params: {
                // res: data
              }
            }
            break
        }
        break
      case 'gotoPay': // 差旅申请
        routerData = {
          name: 'aliPay',
          query: {
            goto: data.goto
          }
        }
        break
      case 'EC_ER_FORM': // 费控报销
        routerData = {
          path: '/expense/erForm',
          query: {
            id: data._bizId_,
            taskId: data._taskId_
          }
        }
        break
    }
  }
  console.log('-----routerData', routerData)
  return routerData
}

function transRouterToUrl(routerData) {
  let queryString = ''
  for (let key in routerData.query) {
    queryString += `${key}=${routerData.query[key]}&`
  }
  queryString = queryString.slice(0, -1)
  let prefix = ''
  if (routerData.name) {
    const path = `/${routerData.name}`
    const url = queryString ? `${path}?${queryString}` : path
    prefix = '/common' + url
  } else if (routerData.path) {
    // EC_ER_FORM 没有router.name;
    const path = `${routerData.path}`
    const url = queryString ? `${path}?${queryString}` : path
    prefix = url
  } else {
    prefix = '/home'
  }

  return prefix
}

function slsLog(object, key = 'sso') {
  if (typeof object != 'object') {
    console.error('log 函数请传入 object参数')
    return
  }
  if (typeof window._ztrace != 'object') {
    console.error('window._ztrace 不能为空')
    return
  }
  window._ztrace && window._ztrace.log(key, { data: object })
}

function loadTaskInfo(url) {
  return new Promise((resolve, reject) => {
    httpGetAxios(
      url,
      {},
      {
        authorization: 'Bearer ' + localStorage.getItem('token')
      }
    )
      .then((data) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function goLoadPage(goUrl, params = {}) {
  // 如果是审批内容,需要根据路由跳转到审批落地页
  // /sys/notify/workflow-task.html?taskId=12654
  if (goUrl.indexOf('workflow-task.html') !== -1) {
    loadTaskInfo(goUrl)
      .then((taskInfo) => {
        console.log(taskInfo)
        slsLog({
          type: 'goLoadPage-1',
          data: { taskInfo, params }
        })
        if (taskInfo.code != '0') {
          console.error(taskInfo)
          alert(taskInfo.msg || 'loadTaskInfo err')
          return
        }
        let _toUrl = transRouterToUrl(getRouteData(taskInfo, goUrl))
        // let _realUrl = location.origin.replace('7088','7099')+'/common' +_toUrl
        let _realUrl = location.origin + _toUrl
        // console.log(_realUrl)
        slsLog({
          type: 'goLoadPage-2',
          data: { taskInfo, goUrl: _realUrl }
        })
        location.replace(_realUrl)
      })
      .catch((err) => {
        console.error(err)
        alert(err.msg || 'loadTaskInfo err')
      })
  } else {
    slsLog({
      type: 'goLoadPage-3',
      data: { params, goUrl }
    })
    location.replace(goUrl)
  }
}

// m-uat.test.mytest.test.cn  输出  .test.cn
// a.m-tirp.shennengy.com.cn   输出  .shennengy.com.cn
// dawei.service.z-trip.cn  输出  .z-trip.cn
// m.z-trip.cn  输出  .z-trip.cn
// m-uat.z-trip.cn  输出  .z-trip.cn
// m-uat.test.mytest.z-trip.cn  输出  .z-trip.cn
function getDomainPath() {
  // 使用 split 方法将域名按照 '.' 分割成数组
  const domainParts = window.location.hostname.split('.')
  // localhost域名
  if (domainParts.length < 2) {
    return ''
  }
  // 获取数组的最后两个元素，即主域名和顶级域名
  const mainDomain = domainParts[domainParts.length - 2]
  const topLevelDomain = domainParts[domainParts.length - 1]

  // 将主域名和顶级域名拼接成新的路径，并在前面添加 '.'
  const getPath = '.' + mainDomain + '.' + topLevelDomain
  // 返回新的路径
  return getPath
}

export {
  clearCookie,
  clearAllEnv,
  transRouterToUrl,
  getBaseUrl,
  isUAT,
  httpGet,
  httpGetAxios,
  isChromeBelow90,
  getPassportUrl,
  getPassportUrlAsync,
  getRouteData,
  toErrorPage,
  getQueryString,
  slsLog,
  loadTaskInfo,
  goLoadPage,
  getDomainPath
}
