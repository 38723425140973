import gcoord from 'gcoord'
import * as u from './util'
import { $t } from '../i18n'
// import axios from 'axios'
import webMap from './map.web'
import { trace } from '../trace'
class Location_wx {
  getAddress(callback) {
    trace.log('location-msg', {
      from: 'wx',
      msg: '开始定位: getAddress'
    })
    const self = this
    window.mconsole &&
      window.mconsole.upYueYing2(
        '定位-wx',
        {
          ua: navigator.userAgent.toLowerCase(),
          userId: localStorage.getItem('userId')
        },
        199,
        0
      )

    if (window.wx) {
      window.$httpUtil.instance
        .get('/auth/getAuthConfig.json', {
          params: {
            client: 'wx',
            url:
              window.$tool && window.$tool.isIosOrAndroid() === 'android' ? location.href.split('#')[0] : window.initUrl
          }
        })
        .then((res) => {
          let rs = res.data
          let data = rs.data
          if (rs.code == '0' && data && data.signature) {
            trace.log('location-msg', {
              from: 'wx',
              msg: '获取定位授权信息',
              data: res
            })
            window.wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: data.appId, // 必填，公众号的唯一标识
              timestamp: data.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature: data.signature, // 必填，签名
              jsApiList: ['getLocation', 'startRecord', 'stopRecord', 'translateVoice'] // 必填，需要使用的JS接口列表
            })
            window.wx.ready(function () {
              trace.log('location-msg', {
                from: 'wx',
                msg: '开始微信定位: 火星坐标系'
              })
              window.wx.getLocation({
                type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: (data) => {
                  // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                  // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                  // var speed = res.speed; // 速度，以米/每秒计
                  // var accuracy = res.accuracy; // 位置精度
                  trace.log('location-msg', {
                    from: 'wx',
                    msg: '微信定位成功-开始转换',
                    data
                  })
                  data.from = 3
                  Promise.resolve(data)
                    .then(webMap.geoconv)
                    .then(webMap.geocoder)
                    .then((res) => {
                      trace.log('location-msg', {
                        from: 'wx',
                        msg: '微信定位成功-转换结束',
                        data: res
                      })
                      if (res && res.status === 0) {
                        let ret = res.result
                        const transPoint = gcoord.transform(
                          [ret.location.lng, ret.location.lat],
                          gcoord.BD09,
                          gcoord.GCJ02
                        )
                        callback({
                          province: ret.addressComponent.province,
                          city: ret.addressComponent.city || ret.addressComponent.district,
                          district: ret.addressComponent.district,
                          address: ret.formatted_address,
                          lon: transPoint[0],
                          lat: transPoint[1]
                        })
                      } else {
                        console.error('wx获取位置出错!')
                      }
                    })
                    .catch((e) => {
                      trace.log('location-msg', {
                        from: 'wx',
                        msg: '微信定位成功-转换失败',
                        data: e
                      })
                    })
                },
                // 用户拒绝获取地理位置
                cancel: (e) => {
                  trace.log('location-msg', {
                    from: 'wx',
                    msg: '微信定位失败-用户拒绝获取地理位置',
                    data: e
                  })
                  callback(e)
                },
                // 获取失败
                fail: (e) => {
                  trace.log('location-msg', {
                    from: 'wx',
                    msg: '微信定位失败-定位获取失败',
                    data: e
                  })
                  callback(e)
                },
                // 获取错误
                error: (e) => {
                  trace.log('location-msg', {
                    from: 'wx',
                    msg: '微信定位失败-定位获取异常',
                    data: e
                  })
                  callback(e)
                }
              })
            })
            window.wx.error(function (res) {
              trace.log('location-msg', {
                from: 'wx',
                msg: '定位授权异常:微信接口加载失败',
                data: res
              })
            })
          } else {
            trace.log('location-msg', {
              from: 'wx',
              msg: '定位授权失败',
              data: res
            })
            callback(rs)
          }
        })
        .catch((err) => {
          trace.log('location-msg', {
            from: 'wx',
            msg: '微信定位失败-无法获取微信鉴权配置',
            data: err
          })
          callback({ code: -1, msg: $t('WFHQWX') })
        })
    } else {
      trace.log('location-msg', {
        from: 'wx',
        msg: '没有加载微信jssdk,重新载入'
      })
      u.loadScript('https://res.wx.qq.com/open/js/jweixin-1.4.0.js', function (rs) {
        if (rs && window.wx) {
          self.getAddress(callback)
        } else {
          trace.log('location-msg', {
            from: 'wx',
            msg: '微信jssdk载入失败'
          })
          callback({ code: -1, msg: $t('WLYC') })
        }
      })
    }
  }
}
export default Location_wx
