import { createI18n } from 'vue-i18n'

/**
 * 支持的语言类型
 */
export const SUPPORT_LOCALES = [
  {
    name: '简体中文',
    code: 'zh_CN',
    from: 'zh'
  },
  {
    name: 'English',
    code: 'en_US',
    from: 'en'
  }
]

/**
 * 获取当前语言, 若没有则走中文
 */
const setupDefaultLocale = () => {
  if (!localStorage.getItem('WEB_LOCALE_LANGUAGE')) {
    const navigatorLanguage = navigator.language.toLowerCase()
    let lang = SUPPORT_LOCALES.find((item) => navigatorLanguage.startsWith(item.from))
    if (lang) {
      localStorage.setItem('WEB_LOCALE_LANGUAGE', lang.code)
    } else {
      localStorage.setItem('WEB_LOCALE_LANGUAGE', 'zh_CN')
    }
  }
}

setupDefaultLocale()

export const locale = sessionStorage.getItem('WEB_LOCALE_LANGUAGE') || localStorage.getItem('WEB_LOCALE_LANGUAGE')

/**
 * 更新语言
 * @param {string} e 语言类型
 */
export const updateLocale = (e) => {
  localStorage.setItem('WEB_LOCALE_LANGUAGE', e)
  sessionStorage.removeItem('WEB_LOCALE_LANGUAGE')
  location.reload()
}

/**
 * 默认的语言实例
 */
export const i18n = setupI18n({
  allowComposition: true,
  legacy: false,
  locale,
  fallbackLocale: 'zh_CN', // 默认语言
  messages: {
    zh_CN: {}
  }
})
i18n.t = i18n.global.t
i18n.$t = i18n.global.t

window.$i18n = () => basicData
window.i18n = i18n.global

/**
 * 基础信息
 */
let basicData = {}

/**
 *
 * @param {string} project 项目名称
 * @param {object} meta
 *      meta: {
 *          name: string,
 *          version: string,
 *          zh_CN: 中文语言(可同步可异步载入)
 *       }
 * @param {string} namespace 命名空间
 */
export const setupBasicLocale = async (meta) => {
  const project = meta.project
  if (meta) {
    let messages = await meta.zh_CN
    if (locale !== 'zh_CN') {
      let r = await getCloudLocaleData(meta.name, meta.version, project)
      messages = { ...messages, ...r }
    }
    basicData[meta.name] = messages
    if (meta.effect) {
      meta.effect(locale)
    }
  }
  i18n.global.setLocaleMessage(locale, basicData)
}
/**
 * vue-router 语言加载器
 * @param {Object} meta: 路由规则内配置的语言载入的版本,
 *      meta: {
 *          name: string,
 *          version: string,
 *          zh_CN: 中文语言(可同步可异步载入)
 *       }
 */
export async function i18nLoader(to, project) {
  const { meta = {} } = to
  let msg = { ...basicData }
  if (meta.i18n) {
    let messages = await meta.i18n.zh_CN()
    if (messages.default) {
      messages = messages.default
    }
    if (locale !== 'zh_CN') {
      let r = await getCloudLocaleData(meta.i18n.name, meta.i18n.version, project)
      messages = { ...messages, ...r }
    }
    msg[meta.i18n.name] = messages
  }
  basicData = { ...msg } //
  i18n.global.setLocaleMessage(locale, msg)
  await setI18nLanguage(i18n, locale)
}

/**
 * 设置默认语言
 */
function setupI18n(options = { locale }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

/**
 * 切换语言
 * @param {*} i18n
 * @param {*} locale
 */
export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

const getCloudLocaleData = async (name, version, project) => {
  let res = {}
  try {
    const r = await fetch(
      `https://static.service.z-trip.cn/resources/i18n/${project}/${name}/${version}_${locale}.json`,
      { mode: 'cors' }
    )
    res = await r.json()
  } catch (e) {
    res = {}
  }
  return res
}

// const getLocaleData = async (name, version) => {
//   const r = await import(`../i18n/MY_PAGE/zh_CN.json`)
//   console.log('getLocaleData', r.default)
//   return r.default
// }

window.I18N_CONFIGURATION = {
  i18n,
  locale,
  updateLocale,
  setupBasicLocale,
  setI18nLanguage,
  i18nLoader,
  SUPPORT_LOCALES
}
