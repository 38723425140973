import { decodeJwtTime } from '../libs'
import { $t } from '../i18n'
import { traceRequestInterceptor, trace } from '../trace'
import { i18nRequestInterceptor } from '../i18n'
import axios from 'axios'
export const appendUAT = (url) => {
  if (!url) {
    console.log('url is null')
    return null
  }
  // 测试环境下：前缀加-uat。
  if (isUAT() && url.indexOf('-uat') == -1) {
    if (url.indexOf('.ding') != -1) {
      url = url.replace('.ding', '.ding-uat')
    } else if (url.indexOf('.service') != -1) {
      // 例如：aoyuan-payment.service.z-trip.cn
      url = url.replace('.service', '.service-uat')
    } else {
      // 例如：m-uat.z-trip.cn ms-uat.z-trip.cn payment-uat.z-trip.cn
      let i = url.indexOf('.')
      if (i != -1) {
        url = url.substr(0, i) + '-uat' + url.substr(i)
      }
    }
  }
  return url.startsWith('http') ? url : 'https://' + url
}
/**
 * 获取默认接口路径
 * @returns  string
 */

/**
 * 判断是否测试环境
 * @returns Boolean
 */
export const isUAT = () => {
  const res =
    window.location.host.indexOf('-uat') != -1 ||
    window.location.host.indexOf('localhost') != -1 ||
    window.location.host.indexOf('192.168') != -1 ||
    window.location.host.indexOf('-dev') != -1
  return res
}

const baseUrl = () => {
  let configurl = null
  if (window.zzzconfig) {
    for (var key in window.zzzconfig.server) {
      if (location.host.indexOf(key) != -1) {
        configurl = window.zzzconfig.server[key]
        break
      }
    }
  }
  if (configurl) {
    return configurl
  } else if (isUAT()) {
    return 'https://ms-uat.z-trip.cn'
  } else {
    return 'https://ms.z-trip.cn'
  }
}
/**
 * 基础配置
 */
export const defaultConfig = {
  NAME: '在途商旅',
  LOGO_ICO: 'https://tem-public.oss-cn-shanghai.aliyuncs.com/logo/z-trip_cn/favicon.ico',
  // 一般包含logo名称的logo
  LOGO_NAME: 'https://tem-public.oss-cn-shanghai.aliyuncs.com/logo/z-trip_cn/logoname.png',
  // 简单logo：loading
  LOGO: 'https://tem-public.oss-cn-shanghai.aliyuncs.com/logo/z-trip_cn/index.png',
  LOGO_WHITE: 'https://static.service.z-trip.cn/logo/z-trip_cn/logowhite.png',
  // 服务地址
  DOMAIN_MS: baseUrl(),
  // 登录中心地址
  DOMAIN_PASSPORT: 'https://passport.z-trip.cn',
  // PC端网站域名
  DOMAIN_WWW: 'www.z-trip.cn',
  DOMAIN_IMG: 'https://img.z-trip.cn',
  DOMAIN_PUB_IMG: 'https://static.service.z-trip.cn',
  // 客服电话
  CUSTOM_PHONE: '400-010-5050',
  // app应用编码，无配置则不绑定设备，不推送
  APP_CODE: null,
  THEME: null
}
const exceptToken = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
}

/** 默认主题 */
export const defaultTheme = 'zblue'

/**
 * token拦截器,如果有问题则直接跳走, 如果无需token则直接下一步
 * @param {notNeedToken}  : Boolean
 */
const tokenRequestInterceptor = (config) => {
  if (config.notNeedToken) {
    return Promise.resolve(config)
  }
  let token = localStorage.getItem('token')
  if (token) {
    const expTime = decodeJwtTime(token)
    if (expTime <= -1) {
      // 如果token异常,则清除token,refreshToken,调到登录页
      console.log('token异常')
      trace.log('custom', {
        name: 'getConfigParamsNext-1',
        data: config
      })
      trace.tag('UN_LOGIN', {
        wpk: {
          category: 207,
          msg: '登录失效'
        }
      })
      exceptToken()
    } else if (expTime - 300 * 1000 < new Date().getTime()) {
      // 如果config的token过期了,那么去gate续期,然后再返回,完成config接口的续期
      // if(isNeedToken(location.href)){ // 这里如果到login2页面,此页面又调用config接口,就会造成死循环;所以需要过滤掉一些不需要权限的页面;
      trace.log('custom', {
        name: 'getConfigParamsNext-2',
        data: config
      })
      window.zzzconfig && window.zzzconfig.goGatePage(location.href, true, 'zconfigNext')
    } else {
      config.headers.Authorization = 'Bearer ' + token
    }
  }
  console.log('---token', config)
  return Promise.resolve(config)
}

const urlRequestInterceptor = (config) => {
  // config.baseURL = window.$ZConfig.getVal('DOMAIN_MS')
  return Promise.resolve(config)
}

const createRequestInstance = () => {
  const instance = axios.create({
    baseURL: defaultConfig.DOMAIN_MS,
    withCredentials: true,
    timeout: 20000,
    headers: {}
  })
  instance.interceptors.request.use(urlRequestInterceptor)
  instance.interceptors.request.use(tokenRequestInterceptor)
  instance.interceptors.request.use(i18nRequestInterceptor) // 国际化
  instance.interceptors.request.use(traceRequestInterceptor) // 日志
  instance.interceptors.response.use(networkResponseInterceptor)
  instance.interceptors.response.use(businessResponseInterceptor)
  console.log('---instance')
  return instance
}

const networkResponseInterceptor = (config) => {
  if (config.status < 400) {
    // 数据补全, 如果没有data 则强制注入一个默认data
    if (!config.data) {
      config.data = {
        code: '0'
      }
    }
    return Promise.resolve(config)
  } else {
    return Promise.reject({
      code: '-404'
    })
  }
}
/**
 * 业务鉴权
 * 如果code为 0则为成功, 其他则为失败
 * 成功返回 Promise.resolve(config.data)
 * 失败返回 Promise.reject(config.data)
 * @param {*} config
 * @returns config.data
 */
const businessResponseInterceptor = (config) => {
  console.log('business', config)
  switch (config.data.code) {
    case '0':
      return Promise.resolve(config.data)
    case '-404':
      alert(config.data.msg || $t('commonconfig-jie-kou-cuo-wu'))
      break
    case '-1':
      alert(config.data.msg || $t('commonconfig-jie-kou-bao-cuo'))
      break
    case '5400':
      exceptToken()
      break
  }
  return Promise.reject(config.data)
}
export const request = createRequestInstance()

export const userState = {
  0: '未审核',
  1: '已审核',
  '-10': '已冻结',
  '-99': '已失效'
}
