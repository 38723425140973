import config from './map.conf'
import jsonp from 'jsonp'
import { $t } from '../i18n'
import { trace } from '../trace'
import { getPositionByBrowerGps, handlerWithPos, getPositionByBd } from './maputil'

class Location_web {
  ready(callback) {
    if (window.BMap) {
      trace.log('location-msg', {
        from: 'web-map',
        msg: 'web地图已初始化'
      })
      console.log('fnCb=====1111百度地图已经存在!!!')
      callback()
    } else {
      trace.log('location-msg', {
        from: 'web-map',
        msg: 'web地图初始化失败'
      })
      alert($t('bai-du-js-jia-zai-shi-bai'))
    }
  }
  /**
   * 百度地图经纬度转换
   * @param points 需要被转换的经纬度
   * @param opts 转换配置请参考(http://lbsyun.baidu.com/index.php?title=webapi/guide/changeposition)
   * @returns {Promise<any>}
   *  From :
   *  1：GPS标准坐标；
      2：搜狗地图坐标；
      3：火星坐标（gcj02），即高德地图、腾讯地图和MapABC等地图使用的坐标；
      4：3中列举的地图坐标对应的墨卡托平面坐标;
      5：百度地图采用的经纬度坐标（bd09ll）；
      6：百度地图采用的墨卡托平面坐标（bd09mc）;
      7：图吧地图坐标；
      8：51地图坐标；
      To:
      目标坐标类型：
      3：火星坐标（gcj02），即高德地图、腾讯地图及MapABC等地图使用的坐标；
      5：百度地图采用的经纬度坐标（bd09ll）；
      6：百度地图采用的墨卡托平面坐标（bd09mc）；
   */
  static geoconv(points, opts = {}) {
    let url = `${config.B_DOMAIN}/geoconv/v1/?`
    url += `coords=${points.longitude},${points.latitude}`
    url += `&from=${opts.from || points.from || 1}&to=${opts.to || 5}`
    url += `&ak=${config.B_KEY}`
    console.log('geoconv', url)
    return new Promise((resolve, reject) => {
      // console.log('geoconv-----1')
      jsonp(url, function (err, ret) {
        // console.log('geoconv-----2',err,ret)
        if (err) {
          console.error(err)
          reject(reject)
        }
        console.log('geoconv-----3', ret)
        if (ret && ret.status === 0) {
          resolve({
            longitude: ret.result[0].x,
            latitude: ret.result[0].y,
            from: points.from || ''
          })
        } else {
          reject($t('web-huo-qu-wei-zhi-chu-cuo'))
          console.error('web获取位置出错!')
        }
      })
    })
  }
  /**
   * 根据经纬度获取位置信息
   * @param pos
   * @param opts 配置(http://lbsyun.baidu.com/index.php?title=webapi/guide/webservice-geocoding-abroad)
   * @returns {Promise<any>}
   */

  //https://api.map.baidu.com/reverse_geocoding/v3/?ak=您的ak&output=json&coordtype=wgs84ll&location=31.225696563611,121.49884033194

  static geocoder(pos, opts = {}) {
    let url = `${config.B_DOMAIN}/geocoder/v2/?callback=renderReverse`
    url += `&coordtype=${typeof opts.coordtype !== 'undefined' ? opts.coordtype : 'gcj02'}`
    url += `&output=json`
    url += `&pois=0`
    url += `&latest_admin=1`
    url += `&location=${pos.latitude},${pos.longitude}`
    url += `&ak=${config.B_KEY}`
    // console.log('geocoder',url)
    return new Promise((resolve, reject) => {
      jsonp(url, (err, pos) => {
        // console.log('geocoder-----1',err)
        if (err) {
          console.error(err)
          reject(err)
        }
        //  console.log('geocoder-----3',pos)
        resolve(pos)
      })
    })
  }

  getAddress(callback) {
    window.mconsole && window.mconsole.upYueYing2('定位-web', {}, 199, 0)
    trace.log('location-msg', {
      from: 'web-map',
      msg: '开始定位: getAddress'
    })
    getLocation(callback)
  }
}

function getLocation(callback) {
  console.log('web定位getLocation')
  getPositionByBrowerGps(
    function (position) {
      console.log(position)
      // 转成百度地图坐标系,然后再查询
      trace.log('location-msg', {
        from: 'web-map',
        msg: '定位完成: getLocation',
        data: position
      })
      handlerWithPos(
        position.data.longitude,
        position.data.latitude,
        function (data) {
          if (data.code == '0') {
            window.mconsole &&
              window.mconsole.upYueYing2(
                '地图定位gps兜底成功',
                {
                  locationData: data
                },
                192,
                0
              )
            callback(data)
            trace.log('location-msg', {
              from: 'web-map',
              msg: '定位成功: getLocation',
              data
            })
          } else {
            window.mconsole &&
              window.mconsole.upYueYing2(
                '地图定位gps兜底转换失败',
                {
                  locationData: data
                },
                192,
                0
              )
            callback(data)
            trace.log('location-msg', {
              from: 'web-map',
              msg: '定位失败: getLocation',
              data
            })
          }
        },
        'BD09'
      )
    },
    function (err) {
      trace.log('location-msg', {
        from: 'web-map',
        msg: '定位异常: getLocation',
        data: err
      })
      console.log('gps定位失败,使用百度地图兜底', err)
      // alert(JSON.stringify(err))
      // console.error(err.msg);
      getPositionByBd(
        function (position) {
          console.log(position)
          trace.log('location-msg', {
            from: 'web-map',
            msg: '定位异常-兜底: getLocation',
            data: position
          })
          handlerWithPos(
            position.data.longitude,
            position.data.latitude,
            function (data) {
              if (data.code == '0') {
                trace.log('location-msg', {
                  from: 'web-map',
                  msg: '定位异常-兜底成功: getLocation',
                  data: data
                })
                window.mconsole &&
                  window.mconsole.upYueYing2(
                    '地图定位百度h5兜底成功',
                    {
                      locationData: data
                    },
                    192,
                    0
                  )
                callback(data)
              } else {
                trace.log('location-msg', {
                  from: 'web-map',
                  msg: '定位异常-兜底失败: getLocation',
                  data: data
                })
                window.mconsole &&
                  window.mconsole.upYueYing2(
                    '地图定位百度h5兜底转换失败',
                    {
                      locationData: data
                    },
                    192,
                    0
                  )
                callback(data)
              }
            },
            'BD09'
          )
        },
        function (errBd) {
          let status = errBd.err?.status || -1
          let errMsg = $t('di-tu-ding-wei-shi-bai-h5-dou-', [status])

          if (status === 8) {
            errMsg += $t('chao-shi')
          } else if (status === 7) {
            errMsg += $t('fu-wu-bu-ke-yong')
          } else if (status === 6) {
            errMsg += $t('mei-you-quan-xian')
          } else if (status === 5) {
            errMsg += $t('fei-fa-qing-qiu')
          } else if (status === 4) {
            errMsg += $t('fei-fa-mi-yue')
          } else if (status === 3) {
            errMsg += $t('dao-hang-jie-guo-wei-zhi')
          } else if (status === 2) {
            errMsg += $t('wei-zhi-jie-guo-wei-zhi')
          } else if (status === 1) {
            errMsg += $t('cheng-shi-lie-biao')
          }
          //BMAP_STATUS_SUCCESS   检索成功。对应数值“0”。
          //BMAP_STATUS_CITY_LIST 城市列表。对应数值“1”。
          //BMAP_STATUS_UNKNOWN_LOCATION  位置结果未知。对应数值“2”。
          //BMAP_STATUS_UNKNOWN_ROUTE 导航结果未知。对应数值“3”。
          //BMAP_STATUS_INVALID_KEY   非法密钥。对应数值“4”。
          //BMAP_STATUS_INVALID_REQUEST   非法请求。对应数值“5”。
          //BMAP_STATUS_PERMISSION_DENIED 没有权限。对应数值“6”。(自 1.1 新增)
          //BMAP_STATUS_SERVICE_UNAVAILABLE   服务不可用。对应数值“7”。(自 1.1 新增)
          //BMAP_STATUS_TIMEOUT   超时。对应数值“8”。(自 1.1 新增)
          console.error(errBd)
          trace.log('location-msg', {
            from: 'web-map',
            msg: '定位异常-兜底失败: getLocation',
            data: {
              err: errMsg,
              errBd: errBd
            }
          })
          // alert("百度地图兜底失败");
          window.mconsole && window.mconsole.upYueYing2(errMsg, { data: errBd }, 192, 1)
          callback({
            code: '-1',
            msg: errMsg,
            status: errBd.err?.status || ''
          })
        },
        'BD09'
      )
    },
    'BD09'
  )
}

export default Location_web
