import { decode } from 'js-base64'
export function decodeJwtTime(jwtStr) {
  const slices = jwtStr.split('.')
  if (typeof slices[1] == 'undefined') {
    return -1
  }
  try {
    let base64 = decode(slices[1])
    const tokenInfo = JSON.parse(base64)
    const expTime = tokenInfo.exp.toString().length === 10 ? tokenInfo.exp * 1000 : tokenInfo.exp
    return expTime
  } catch (err) {
    // 无效token;
    console.error(err)
    return -1
  }
}

function isBlockDomain() {
  if (window.zzzconfig && window.zzzconfig.isBlockDomain) {
    return window.zzzconfig.isBlockDomain()
  } else {
    return false
  }
}
export function isUAT() {
  return (
    window.location.host.indexOf('-uat') != -1 ||
    window.location.host.indexOf('-dev') != -1 ||
    window.location.host.indexOf('localhost') != -1 ||
    window.location.host.indexOf('192.168') != -1
  )
}
// 第三方域名屏蔽时, 地理位置,支付,分享,支付,定位需要屏蔽掉;无法加载第三方js;
// 返回true,说明禁用第三方js,默认返回flase,不禁用
// var zzzconfig = {
//     server: {
//       "fosun": "https://ms-trip.fosun.com",
//       "fosunadmin": "https://ms-trip.fosun.com",
//       "chambroad": "https://ms-trip.chambroad.com",
//       "jbadmin": "https://ms-trip.chambroad.com",
//       "sunac": "https://ms-trip.sunac.com.cn",
//       "shenergy": "https://ms-trip.shenergy.com.cn",
//       "-test.z-trip.cn": "https://ms-test.z-trip.cn"
//     },
//     blockUlr: ['yangguangshanglv']
//   }
export function isHideOtherDomain() {
  // HIDE_OTHER_DOMAIN ,还要根据zzzconfig的blockUrl判断是否屏蔽; 因为有可能是无登录状态;无登录状态根据域名决定是否注入第三方js
  // 军工白名单需要返回true;
  if (isBlockDomain() || (window.$ZConfig && window.$ZConfig.getVal('HIDE_OTHER_DOMAIN') === true)) {
    console.log('HIDE_OTHER_DOMAIN')
    return true
  } else {
    return false
  }
}

export function loadJSFn(url, sucCb, failCb) {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url + (url.indexOf('?') === -1 ? '?' : '&') + 'random=' + Math.random()
  // script.src = url;
  if (typeof sucCb === 'function') {
    if (script.readyState) {
      // IE
      script.onreadystatechange = function () {
        if (script.readyState == 'loaded' || script.readyState == 'complete') {
          script.onreadystatechange = null
          sucCb()
        }
      }
    } else {
      // Other browsers
      script.onload = function () {
        sucCb()
      }
    }
  }
  if (typeof failCb === 'function') {
    script.onerror = function () {
      failCb()
    }
  }
  document.body.appendChild(script)
}

export function slsLog(object) {
  if (typeof object != 'object') {
    console.error('slsLog 函数请传入 object参数')
    return
  }
  window._ztrace && window._ztrace.log('slsLog', object)
}

export function debounce(fn, wait) {
  let timer
  let timeStamp = 0
  let context, args
  let run = () => {
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
  let clean = () => {
    clearTimeout(timer)
  }
  return function () {
    context = this
    args = arguments
    let now = new Date().getTime()
    if (now - timeStamp < wait) {
      clean() // clear running timer
      run() // reset new timer from current time
    } else {
      run() // last timer alreay executed, set a new timer
    }
    timeStamp = now
  }
}

export function throttle(func, delay) {
  var timer = null
  return function () {
    var context = this
    var args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        func.apply(context, args)
        timer = null
      }, delay)
    }
  }
}

export function getAppVersion() {
  // 正则表达式模式，查找 "ztflutter/数字.数字.数字" 这样的格式  cqflutter
  const pattern = new RegExp(`flutter/([\\d\\.]+)`)

  const match = window.navigator.userAgent.toLowerCase().match(pattern)
  if (match && match[1]) {
    return match[1] // 返回找到的版本号
  }
  return null // 如果没有找到匹配项，返回null
}
/**
 * 对比app的版本号
 * @param {*} version1
 * @param {*} version2
 * @returns  -1 说明d 小于version2  0 相等  1 说明version1 大于version2
 *
 */
export function compareVersions(version2) {
  let version1 = getAppVersion() || '3.0.0'
  const v1 = version1.split('.').map(Number)
  const v2 = version2.split('.').map(Number)

  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const num1 = v1[i] || 0
    const num2 = v2[i] || 0

    if (num1 > num2) {
      return 1
    } else if (num2 > num1) {
      return -1
    }
  }

  return 0
}

export function showTips(msg, timeout = 2000) {
  let tipsDom = getTipsDom(msg)
  var parentElement = document.body
  parentElement.appendChild(tipsDom)
  setTimeout(() => {
    parentElement.removeChild(tipsDom)
  }, timeout)
}

function getTipsDom(msg) {
  const cssStr =
    '#main-tips { position: fixed;z-index: 9999; white-space: nowrap;  line-height: 30px; padding: 0 40px;text-align: center; display: block;justify-content: center;height: 30px; background: #333; color: #fff; opacity: 0.7; top: 50%; left: 50%;transform: translateX(-50%);border-radius: 5px; }'
  const tipstyle = document.createElement('style')
  tipstyle.attributes.style = 'text/css'
  tipstyle.textContent = cssStr
  document.head.appendChild(tipstyle)
  let divElement = document.createElement('div')
  divElement.id = 'main-tips'
  divElement.innerHTML = msg
  return divElement
}

/**
 * 获取在途jscore版本, 0 为不支持,用于jsbridge版本统一
 * @returns Number
 */
export const getZtCoreVersion = () => {
  let ver = window.navigator.userAgent.toLocaleLowerCase().match(/ztjscore\/\d+/)
  if (ver) {
    return Number(ver[0].split('/')[1])
  }
  return 0
}
