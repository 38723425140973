const loadScript = function (url, callback) {
  let script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  script.onload = script.onreadystatechange = function () {
    callback && callback(!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')
  }
  script.onerror = function () {
    callback && callback(false)
  }
  script.setAttribute('src', url)
  document.body.appendChild(script)
}
/**
 * 判断当前设备
 * @returns {string}
 */
const isIosOrAndroid = function () {
  let u = navigator.userAgent
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  let isStr = ''
  if (isAndroid) {
    isStr = 'android'
  }
  if (isiOS) {
    isStr = 'ios'
  }
  return isStr
}
export { loadScript, isIosOrAndroid }
