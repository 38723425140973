/*
 * @Author: youthscc(蒋松)
 * @Date: 2024-06-21 14:37:30
 * @LastEditors: youthscc(蒋松)
 * @LastEditTime: 2024-06-24 15:44:58
 * @Description: 定位原生调用方法优化升级版
 */
import { showLocationTips } from "./map.trigger";
import { trace } from "../trace";
import { $t } from "../i18n";

class Location_flutter_v2 {
  getPermission(isPrompt) {
    trace.log("location-msg", {
      from: "flutter",
      msg: "开始授权: getPermission",
    });
    return new Promise((resolve, reject) => {
      if (typeof window.flutter_inappwebview === "undefined") {
        reject({
          code: "-3",
          msg: $t("fei-flutter-rong-qi"),
        });
      }
      trace.log("location-msg", {
        from: "flutter",
        msg: "开始Flutter授权: getPermission",
      });
      let retPromise =
        window.flutter_inappwebview &&
        window.flutter_inappwebview.callHandler(
          "getPermissionAndLocationInfo",
          JSON.stringify({ callFnName: "flutterLocaionCb", prompt: isPrompt })
        );
      retPromise
        .then((data) => {
          console.log("getPermission1111111111" + JSON.stringify(data));
          let retJson = data || {};
          if (retJson && retJson.code != 0) {
            const logMsgObject = {
              100: '定位权限未开启，请开启权限',
              101: '定位初始化失败，请重试',
              102: '定位获取失败',
              103: '异常：定位上下文错误',
              999: '异常：flutter定位方法调用错误'
            }
            const msg = logMsgObject[retJson.code] || logMsgObject[999];
            trace.log("location-msg", {
              from: "flutter",
              msg: `${msg}: getPermission`,
              data: retJson,
            });
            reject({
              code: "-1",
              msg: retJson.message || retJson.msg || msg,
            });
          } else {
            trace.log("location-msg", {
              from: "flutter",
              msg: "定位权限已开启: getPermission(zt-core)",
              data: retJson,
            });
            resolve({
              code: "0",
            });
          }
        })
        .catch((err) => {
          trace.log("location-msg", {
            from: "flutter",
            msg: "请确认开启定位权限: getPermission",
            data: err,
          });
          reject({
            code: "-2",
            msg: $t("qing-que-ren-kai-qi-ding-wei-q"),
          });
        });
    });
  }

  getAddress(callback, isPrompt = false) {
    trace.log("location-msg", {
      from: "flutter",
      msg: "开始定位: getAddress",
    });
    showLocationTips();
    console.log("Flutter ------getAddress");
    this.getPermission(isPrompt)
      .then((data) => {
        if (data.code !== "0") {
          let msgErr = data.msg || $t("mei-you-quan-xian");
          trace.log("location-msg", {
            from: "flutter",
            msg: "没有定位权限 -99: getAddress",
            data: data,
          });
          callback({
            code: "-99",
            msg: msgErr,
          });
          return;
        }
        window.mconsole &&
          window.mconsole.upYueYing2("定位-flutter", {}, 199, 0);
        window.flutterLocaionCb = function (retInfo) {
          const ret = JSON.parse(retInfo) || {};
          if (ret && ret.longitude && ret.latitude) {
            window.mconsole &&
              window.mconsole.endUploadByPage("flutter_map_time", {
                w_succ: 0,
                category: 198,
                msg: "flutter定位耗时",
              });
            window.mconsole &&
              window.mconsole.upYueYing2("flutter原生地图定位成功", {}, 192, 0);
            trace.log("location-msg", {
              from: "flutter",
              msg: "定位成功: getAddress",
              data: retInfo,
            });
            callback({
              code: 0,
              province: ret.province,
              city: ret.city || ret.district,
              district: ret.district,
              lon: ret.longitude,
              lat: ret.latitude,
              street: ret.street,
              address: ret.address,
              isTransformed: true, // 是否精确,为ture,gcoord.transform不需要纠正了; 百度地图已经精准定位,所以不需要纠正了;
            });
          } else {
            console.log("------22");
            window.mconsole &&
              window.mconsole.upYueYing2(
                "flutter原生地图定位失败,使用h5兜底",
                {},
                192,
                1
              );
            // getAddressByBlow(callback)
            if (window.$tool?.webMap) {
              trace.log("location-msg", {
                from: "flutter",
                msg: "定位失败-h5兜底: getAddress",
                data: retInfo,
              });
              window.$tool.webMap.getAddress(callback);
            } else {
              trace.log("location-msg", {
                from: "flutter",
                msg: "定位失败-h5兜底失败 $tool.webMap 尚未加载: getAddress",
                data: retInfo,
              });
              callback({
                code: -1,
                msg: $t("toolwebmap-shang-wei-jia-zai"),
              });
            }
          }
          // 回调之后把回调销毁;
          setTimeout(() => {
            delete window.flutterLocaionCb;
          }, 100);
        };
        // window.mconsole &&
        //   window.mconsole.startUploadByPage("flutter_map_time");
        // window.$flutter.getLocation("flutterLocaionCb");
      })
      .catch((err) => {
        let msgErr = err.msg || $t("app-wei-zhi-quan-xian-yi-chang");
        console.error(msgErr);
        trace.log("location-msg", {
          from: "flutter",
          msg: "定位失败-权限异常-99: getAddress",
          data: err,
        });
        callback({
          code: "-99",
          msg: msgErr,
        });
      });
  }
}
export default Location_flutter_v2;
