// import axios from 'axios'
import gcoord from 'gcoord'
import webMap from './map.web'
import { $t } from '../i18n'
import { trace } from '../trace'
// 鉴权过就不需要再鉴权了
let workWxConfiged = false

// 企业微信环境中定位:https://work.weixin.qq.com/api/doc/90000/90136/94313

function uploadWorkWxErr(params) {
  console.log('uploadWorkWxErr', params)
  window.mconsole && window.mconsole.upYueYing('企业微信地图授权异常', params, 145, 0)
}
function uploadWorkWxSuc(params) {
  window.mconsole && window.mconsole.upYueYing('企业微信地图授权成功', params, 145, 1)
}
class Location_workwx {
  getAddress(callback) {
    trace.log('location-msg', {
      from: 'qywx',
      msg: '企微定位: getAddress',
      data: sessionStorage.getItem('qy_corp')
    })
    if (!window.wx) {
      trace.log('location-msg', {
        from: 'qywx',
        msg: '企微定位异常-执行web兜底'
      })
      new webMap().getAddress(callback)
      // callback({code: -1, msg: '环境初始化异常'})
      return
    }
    window.mconsole &&
      window.mconsole.upYueYing2(
        '定位-workwx',
        {
          ua: navigator.userAgent.toLowerCase(),
          userId: localStorage.getItem('userId')
        },
        199,
        0
      )

    let corpCode = sessionStorage.getItem('qy_corp')
    if (!corpCode) {
      trace.log('location-msg', {
        from: 'qywx',
        msg: '企微定位异常-corpCode不存在-执行web兜底'
      })
      new webMap().getAddress(callback)
      return
    }
    if (workWxConfiged) {
      trace.log('location-msg', {
        from: 'qywx',
        msg: '企微定位:已鉴权'
      })
      this.getLocation(callback)
      return
    }
    const params = {
      corp: corpCode,
      url: location.href.split('#')[0],
      client: 'qywx'
    }
    window.$httpUtil.instance
      .get('/auth/getAuthConfig.json', {
        params
      })
      .then((res) => {
        trace.log('location-msg', {
          from: 'qywx',
          msg: '企微定位-取得鉴权信息',
          data: res.data
        })
        let rs = res.data
        let data = rs.data
        console.log('auth/getAuthConfig.json', res)
        if (rs.code != '0' || !data || !data.signature) {
          trace.log('location-msg', {
            from: 'qywx',
            msg: '企微定位-鉴权结束-无法获得鉴权信息'
          })
          uploadWorkWxErr(Object.assign({}, { code: -1, msg: '无法获得鉴权信息' }, res))
          callback({ code: -1, msg: '无法获得鉴权信息' })
          return
        }
        window.wx.agentConfig({
          corpid: data.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: data.agentId, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: data.timeStamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getLocation', 'startRecord', 'stopRecord', 'translateVoice'], //必填，传入需要使用的接口名称
          success: (res) => {
            trace.log('location-msg', {
              from: 'qywx',
              msg: '企微定位-鉴权成功',
              data: res
            })
            this.getLocation(callback)
          },
          fail: (res) => {
            trace.log('location-msg', {
              from: 'qywx',
              msg: '企微定位-鉴权失败',
              data: res
            })
            callback({ code: -1, msg: 'fail:' + JSON.stringify(res) })
            uploadWorkWxErr({
              code: -1,
              msg: 'fail:' + JSON.stringify(res),
              params: JSON.stringify(params),
              data: JSON.stringify(data),
              rs: JSON.stringify(rs)
            })
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            } else {
              alert(JSON.stringify(res))
            }
          }
        })
      })
      .catch((err) => {
        console.error(err)
        callback({ code: -1, msg: JSON.stringify(err) })
        uploadWorkWxErr({ code: -1, msg: JSON.stringify(err) })
      })
  }
  getLocation(callback) {
    window.wx.getLocation({
      type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: function (data) {
        // workWxConfiged = true
        // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        // var speed = res.speed; // 速度，以米/每秒计
        // var accuracy = res.accuracy; // 位置精度
        data.from = 3
        trace.log('location-msg', {
          from: 'qywx',
          msg: '企微定位-定位成功',
          data
        })
        try {
          Promise.resolve(data)
            .then(webMap.geoconv)
            .then(webMap.geocoder)
            .then((res) => {
              if (res && res.status === 0) {
                const ret = res.result
                console.log('=====飞书111110000' + JSON.stringify(ret))
                const transPoint = gcoord.transform([ret.location.lng, ret.location.lat], gcoord.BD09, gcoord.GCJ02)
                let paramsMsg = {
                  province: ret.addressComponent.province,
                  city: ret.addressComponent.city || ret.addressComponent.district,
                  district: ret.addressComponent.district,
                  address: ret.formatted_address,
                  lon: transPoint[0],
                  lat: transPoint[1]
                }
                console.log('=====飞书111112222' + JSON.stringify(paramsMsg))
                uploadWorkWxSuc(paramsMsg)
                trace.log('location-msg', {
                  from: 'qywx',
                  msg: '企微定位-转换成功',
                  data: paramsMsg
                })
                callback(paramsMsg)
              } else {
                trace.log('location-msg', {
                  from: 'qywx',
                  msg: '企微定位-转换失败',
                  data: res
                })
                callback({ code: -1, msg: 'wx.getLocation fail' })
                // 第三方私有化部署,不要忘记配置百度地图域名白名单!!!
                console.error('workwx获取位置出错!')
                uploadWorkWxErr(Object.assign({}, 'workwx获取位置出错'))
              }
            })
        } catch (err) {
          uploadWorkWxErr(Object.assign({ catch: 'catch(err)' }, err))
          console.error(err)
        }
      },
      fail: function (errorMsg) {
        // alert(JSON.stringify(errorMsg))
        console.log((errorMsg && errorMsg.message) || 'error')
        trace.log('location-msg', {
          from: 'qywx',
          msg: '企微定位失败-网络异常，无法获取当前位置',
          data: errorMsg
        })
        uploadWorkWxErr(Object.assign({}, (errorMsg && errorMsg.message) || 'wx.getLocation fail'))
        callback({ code: -2, msg: $t('WLYC3') })
      }
    })
  }
}
export default Location_workwx
