// 原型拓展
// 兼容低版本的 数组没有fill 方法；
Array.prototype.fill ||
  (Array.prototype.fill = function (value, start, end) {
    start || (start = 0)
    end || (end = this.length - 1)
    for (var i = start; i <= end; i++) this[i] = value
    return this
  })
Array.prototype.find ||
  (Array.prototype.find = function (callback) {
    if (!callback) return
    for (var i = 0; i < this.length - 1; i++) {
      var item = this[i]
      var result = callback(item, i, this)
      if (result) return item
    }
  })
Array.prototype.includes ||
  (Array.prototype.includes = function (val) {
    for (let i = 0; i < this.length - 1; i++) {
      let result = this[i] == val
      if (result) return true
    }
    return false
  })
String.prototype.startsWith ||
  (String.prototype.startsWith = function (a) {
    return this.indexOf(a) === 0
  })
// https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
if (!Array.prototype.findIndex) {
  /* eslint-disable no-extend-native */
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }
      var o = Object(this)
      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0
      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }
      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1]
      // 5. Let k be 0.
      var k = 0
      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return k
        }
        // e. Increase k by 1.
        k++
      }
      // 7. Return -1.
      return -1
    }
  })
}
