import { loadMicroApp } from 'qiankun'

export default () => {
  // 加载微应用
  if (!location.pathname.startsWith('/mg/ai')) {
    let container = document.getElementById('chat-container')
    if (!container) {
      container = document.createElement('div')
      container.id = 'chat-container'
      document.body.appendChild(container)
    }

    loadMicroApp({
      name: 'chat',
      entry: '/m/ai/index.html',
      container: '#chat-container'
    })
  }
}
