import { device } from './device'
import { isHideOtherDomain } from '@/lib/libs'
let winDev = null
/**
 * 友盟统计
 */
function YOUMONG(w, d, s, q, i) {
  return new Promise(function (resolve) {
    // 注入sdk
    w[q] = w[q] || []
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    j.async = true
    j.id = 'beacon-aplus'
    j.src = 'https://d.alicdn.com/alilog/mlog/aplus/' + i + '.js'
    f.parentNode.insertBefore(j, f)
    resolve()
  })
}
function getStoreUserKey() {
  let localUserInfo = localStorage.getItem('localUserInfo') || '{}'
  try {
    localUserInfo = JSON.parse(localUserInfo)
  } catch (err) {
    localUserInfo = {}
  }
  return function (key) {
    if (localUserInfo && localUserInfo[key]) {
      return localUserInfo[key] + ''
    } else {
      return ''
    }
  }
}
function appendCommonParams(params) {
  let _paramIndex = 0
  if (params.action === 'aplus.record') {
    _paramIndex = 2
  } else if (params.action === 'aplus.sendPV') {
    _paramIndex = 1
  }
  if (_paramIndex === 0) {
    return params
  }
  const userInfoFn = getStoreUserKey()
  let newParams = {
    // ...winDev,
    isH5: 'true',
    ip: window.returnCitySN?.cip || '',
    Current_URL: location.href ? location.href.split('?')[0] : '',
    Referrer: document.referrer || '',
    //Pre_URL: location.origin +'' +window.history?.state?.back || '',
    userId: localStorage.getItem('userId') || '',
    userMobile: userInfoFn('mobile'),
    Partner_Id: userInfoFn('partnerId'),
    Rank_Name: userInfoFn('rankName'),
    Partner_Name: userInfoFn('partnerName'),
    Browser_Version: winDev && winDev['H5_browser_version'],
    Browser_Name: winDev && winDev['Browser'],
    Screen_Height: window.screen.height + '',
    Screen_Width: window.screen.width + '',
    Manufacturer: '', //设备品牌 BrandUa.getBrand(ua) 新增js库
    UserName: userInfoFn('userName'),
    NowTime: userInfoFn('date')
  }
  console.log(newParams)
  if (
    window.history?.state &&
    window.history?.state.back !== null &&
    typeof window.history?.state.back !== 'undefined'
  ) {
    newParams.Pre_URL = location.origin + '' + window.history?.state.back
  }
  Object.keys(newParams).forEach((key) => {
    if (typeof newParams[key] === 'undefined' || newParams[key] === null || newParams[key] == '') {
      delete newParams[key]
    }
  })
  let _ret = Object.assign({}, params)
  //  arguments: ['Action_user_device', 'CLK', Object.assign({}, {pvPath: location.href}, device())]
  if (_ret.action === 'aplus.record' || _ret.action === 'aplus.sendPV') {
    let _tempArgs = Object.assign({}, newParams, _ret.arguments[_paramIndex] || [])
    // _ret = Object.assign({},params,{
    //   arguments:
    // })
    _ret.arguments = params.arguments.slice(0, _paramIndex).concat(_tempArgs)
  }
  console.log('PV_EVT', JSON.stringify(_ret))
  return _ret
}
function isUAT() {
  return (
    window.location.host.indexOf('-uat') != -1 ||
    window.location.host.indexOf('-dev') != -1 ||
    window.location.host.indexOf('localhost') != -1 ||
    window.location.host.indexOf('192.168') != -1
  )
}

function init_ym_point() {
  // 对外暴露api

  winDev = device()
  window.$ztDataAction = {
    device: winDev,
    push: function (params = { action: '', arguments: [] }) {
      if (isHideOtherDomain() || isUAT()) {
        // 第三方js无法加载,无法上报, uat 隐藏上报
        return
      }

      const aplus_queue = window.aplus_queue || window.aplus || {}
      if (typeof aplus_queue === 'undefined') {
        console.error('aplus_queue is null, push 失败!')
        return
      }
      // 这里新增通用参数
      try {
        let paramsMerge = appendCommonParams(params)
        // if(location.origin.includes('//m.z-trip.cn') || params.action === 'aplus.setMetaInfo'){
        console.log(paramsMerge)
        aplus_queue.push(paramsMerge)
        // }
      } catch (err) {
        console.error('appendCommonParams ERROR', err)
      }
    }
  }
  // 集成应用的appKey
  window.$ztDataAction.push({
    action: 'aplus.setMetaInfo',
    arguments: ['appKey', '615292f514e22b6a4f0fe7bb']
  })
  // 设置手动Pv
  window.$ztDataAction.push({
    action: 'aplus.setMetaInfo',
    arguments: ['aplus-waiting', 'MAN']
  })

  // 设备分析
  if (localStorage.getItem('userId')) {
    window.$ztDataAction.push({
      action: 'aplus.setMetaInfo',
      arguments: ['_user_id', localStorage.getItem('userId')]
    })
  }
  window.$ztDataAction.push({
    action: 'aplus.record',
    arguments: ['Action_user_device', 'CLK', Object.assign({}, { pvPath: location.href }, winDev)]
  })
  // 首次加载-刷新页面
  window.$ztDataAction.push({
    action: 'aplus.sendPV',
    arguments: [{ is_auto: false }, Object.assign({}, { pvPath: location.href }, winDev)]
  })
  // 路由变化上报Pv
  function routeChange(e) {
    if (!e.state) {
      return
    }
    window.$ztDataAction.push({
      action: 'aplus.sendPV',
      arguments: [{ is_auto: false }, Object.assign({}, { pvPath: e.state.current }, winDev)]
    })
  }
  window.addEventListener('popstate', routeChange)
}
// 性能
function YUEYING(c, i, e, b) {
  const h = i.createElement('script')
  const f = i.getElementsByTagName('script')[0]
  h.id = 'dd_watch'
  h.type = 'text/javascript'
  h.crossorigin = true
  h.onload = function () {
    // 上报限制
    const allow = true
    // const allow =
    //   location.origin.includes('m.z-trip.cn') &&
    //   (window.$ZConfig.getVal('ENV') === 'master' ||
    //     window.$ZConfig.getVal('ENV') === 'green')
    // 钉钉监控
    c[b] ||
      (c[b] = new c.wpkReporter({
        bid: 'dta_2_38110',
        spa: true,
        uid: function () {
          return localStorage.getItem('userId') || ''
        },
        ignoreScriptError: true,
        beforeSend: function () {
          if (allow) return true
          return false
        },
        plugins: []
      }))
    c[b].installAll()
  }
  f.parentNode.insertBefore(h, f)
  h.src = e
}
export async function youMongEvent() {
  // 钉钉的性能监控关闭,使用岳鹰了
  // TODO 如果是钉钉的应用,
  // app38110.eapps.dingtalkcloud.com
  if (location.origin.includes('dingtalkcloud.com')) {
    // const url = window.location.pathname
    // 性能
    await YUEYING(window, document, 'https://g.alicdn.com/woodpeckerx/jssdk??wpkReporter.js', '__wpk')
  }
  // 友盟

  if (window.zzzconfig && window.zzzconfig.isBlockDomain && window.zzzconfig.isBlockDomain()) {
    // console.error('第三方域名不使用友盟')
    // 第三方域名不使用友盟
    window.$ztDataAction = {
      device: device(),
      push: function () {}
    }
    return
  }
  const r = Date.now()
  await YOUMONG(window, document, 'script', 'aplus_queue', '203467608').then(() => {
    init_ym_point()
  })
  console.log('YOUMONG', Date.now() - r)
}
