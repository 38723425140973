const CS = {
  companyBaseLink: 'https://z-trip.s2.udesk.cn/im_client/',
  // 帮助中心
  help: {
    pluginKey: 'help',
    web_plugin_id: null,
    serverImage: 'https://static.service.z-trip.cn/app/other/cs/help.png',
    defaultProduct: {
      send: false,
      title: '帮助中心',
      image: 'https://static.service.z-trip.cn/logo/z-trip_cn/logoname.png'
    },
    defaultItems: []
  },
  // 票务组（机票/火车）
  ticket: {
    pluginKey: 'ticket',
    web_plugin_id: '10054309',
    serverImage: 'https://static.service.z-trip.cn/app/other/cs/ticket.png',
    defaultProduct: {
      send: false,
      title: '票务中心',
      image: 'https://static.service.z-trip.cn/app/ztrip/flightBanner.png'
    },
    defaultItems: []
  },
  // 票务鹰眼组（机票/火车）
  ticket_eye: {
    pluginKey: 'ticket_eye',
    web_plugin_id: '10054309',
    serverImage: 'https://static.service.z-trip.cn/app/other/cs/ticket.png'
  },
  hotel: {
    pluginKey: 'hotel',
    web_plugin_id: '10054316',
    serverImage: 'https://static.service.z-trip.cn/app/other/cs/hotel.png',
    defaultProduct: {
      send: false,
      title: '酒店中心',
      image: 'https://static.service.z-trip.cn/app/ztrip/hotelBanner.png'
    },
    defaultItems: []
  },
  hotel_eye: {
    pluginKey: 'hotel_eye',
    web_plugin_id: '10054316',
    serverImage: 'https://static.service.z-trip.cn/app/other/cs/hotel.png'
  },
  mice: {
    pluginKey: 'mice',
    web_plugin_id: '10064648',
    serverImage: 'https://static.service.z-trip.cn/app/other/cs/hotel.png',
    defaultItems: []
  },
  ...window.BASIC_CONF.CS
}
const KEFU_DOM_ID = 'udesk-cs'
const IS_OPEN_ROUTER = true
// 打开云客服
CS.open = async function (csType) {
  /*埋点*/
  window.$ztDataAction &&
    window.$ztDataAction.push({
      action: 'aplus.record',
      arguments: ['Button_Click_Customer_Service', 'CLK']
    })
  if (CS.loading) {
    return
  }
  CS.loading = true
  // Vue.$w.loading.show()
  let localUserInfo = localStorage.getItem('localUserInfo') || '{}'
  localUserInfo = JSON.parse(localUserInfo)
  const link = await CS.getCsOnlineLink(csType)
  // const url_ = 'https://7m.service.z-trip.cn/wapchat.html?accessId=ef9dbad0-…_uatztrip&urlTitle=ztrip&fromUrl=localhost:7099&otherParams={"nickName":"大数据蔡伟超","showFileUpload":true}&customField={"公司":"大批量数据测试企业","手机号":"13248231369","性别":"男","差旅级别":"员工","vip":"vip","渠道":"ztrip"}'
  if (!link) {
    CS.loading = false
    return
  }
  // const IS_OPEN_ROUTER = location.href.indexOf('kefu=open') !==-1 || false  // 这里做一个开关, 版本上线前先关闭,下一个迭代测试完后再开启
  if (IS_OPEN_ROUTER) {
    // 通过路由跳转方式打开common/kefu页面
    let kefu_params = {
      link,
      from: window.location.href
    }
    if (localUserInfo.mobile !== undefined) {
      kefu_params.link += '&mobile=' + localUserInfo.mobile
    }
    let _strParams = JSON.stringify(kefu_params)
    window.localStorage.setItem('cs7_kefu_params', _strParams)
    if (window.flutter_inappwebview) {
      window.$flutter.launchUrl({
        type: 'url',
        data: kefu_params.link,
        title: '在线客服',
        isBrowser: false
      })
    } else {
      window.$jump && window.$jump.goLocPage('/common/kefu', null, false)
    }
  }
  CS.setUnReadCSCount(0)
  CS.loading = false
}
// 之前的云客服咨询对象信息转换成七陌的咨询对象
CS.getProductInfo = function () {
  const productInfo = window.getProductInfo ? window.getProductInfo() : null
  if (!productInfo) {
    return null
  }
  let p = {
    visible: true,
    target: ''
  }
  let subTitlesKey = ['sub_title', 'other_title_one', 'other_title_two', 'other_title_three']
  let i = 0
  for (let k in productInfo) {
    // 不支持自动发送
    if (k == 'send') {
      continue
    }
    if (k == 'image') {
      p.img = productInfo[k]
    } else if (k == '价格') {
      p.price = '价格：' + productInfo[k]
    } else if (k == 'title') {
      p.title = productInfo.title
    } else {
      if (!subTitlesKey[i]) {
        console.log('字段不够存储')
        i = 1
      }
      if (p[subTitlesKey[i]]) {
        p[subTitlesKey[i]] += '｜' + k + '：' + (productInfo[k] || '')
      } else {
        p[subTitlesKey[i]] = k + '：' + (productInfo[k] || '')
      }
      i++
    }
  }
  return p
}
CS.getCsOnlineLink = async function (csType) {
  const userInfo = await $ZConfig.getCurUserInfo()
  if (!userInfo.userId) {
    alert('无法获取用户信息，请稍后')
    return
  }
  // 渠道
  const channel = $ZConfig.getVal('channel') || ''
  const csObj = CS[csType] || CS.help
  // 七陌文档地址：http://developer.7moor.com/dc/#/online/webCustomerService
  let qimoUser = {
    nickName: userInfo.name,
    showFileUpload: true
  }
  // 指定技能组
  if (csObj.web_plugin_id) {
    qimoUser.peerId = csObj.web_plugin_id
  }
  let productInfo = CS.getProductInfo()
  if (productInfo) {
    qimoUser.productInfo = productInfo
  }
  let customField = {
    公司: userInfo.companyName,
    手机号: userInfo.mobile,
    性别: userInfo.gender === 1 ? '女' : userInfo.gender === 0 ? '男' : '未设',
    差旅级别: userInfo.rankName,
    vip: userInfo.vip ? 'vip' : 'normal',
    渠道: channel
  }
  let client = {
    clientId: getQimoClientId(userInfo.userId) + '@' + channel,
    urlTitle: channel,
    fromUrl: location.host,
    otherParams: JSON.stringify(qimoUser),
    customField: JSON.stringify(customField),
    importsid: userInfo.userId + '_' + channel
  }
  // let url = 'https://ykf-webchat.7moor.com/wapchat.html?accessId=ef9dbad0-5c0b-11ec-8995-a348f57cc9ee'
  let accessId = $ZConfig.getVal('TM_CS_URL') || 'ef9dbad0-5c0b-11ec-8995-a348f57cc9ee'
  let url = `https://7m.service.z-trip.cn/wapchat.html?accessId=${accessId}`

  for (let k in client) {
    url += '&' + k + '=' + client[k]
  }
  console.log(url)
  return url

  // window.qimoClientId = {
  //   userId: getQimoClientId(userInfo.userId) + channel, // 自定义用户的唯一id，不能传空字符串、null、中文，id由字母大小写、数字及'-'和 '_'组成
  //   priority: false, //是否优先进线设置，非必填，值为boolean类型的true或false
  //   nickName: userInfo.name,//自定义用户昵称，定义用户昵称则userId必传
  //   agent: null,//指定接入坐席，必输是渠道技能组中配置的坐席，非必传
  //   peerId: csObj.web_plugin_id,//指定技能组ID，后台互联网渠道技能组可以查看
  //   customField: { //扩展字段，json对象，对象中的key-value都自定义
  //     "vip": userInfo.vip ? 'vip' : 'normal',
  //     "性别": userInfo.gender === 1 ? '女' : (userInfo.gender === 0 ? '男' : '未设'),
  //     "差旅级别": userInfo.rankName,
  //     "渠道": channel
  //   }
  // }
  // console.log(window.qimoClientId)
  // if (window.qimoChatClick) {
  //   window.qimoChatClick()
  // } else {
  //   alert('客服插件加载失败')
  // }
}
function getQimoClientId(userId) {
  // 计算用户唯一标识，gray&master&green&null | uat&prj | dev&test
  let env = $ZConfig.getVal('ENV')
  if (!env || env == 'master' || env == 'green' || env == 'gray') {
    return userId
  }
  if (env == 'uat' || env == 'prj') {
    return userId + '_uat'
  }
  return userId + '_dev'
}
// 清除未读消息
CS.clearUnReadMessage = function () {}
CS.initDom = function () {
  const udeskId = KEFU_DOM_ID
  const dom = document.createElement('div')
  const img = document.createElement('img')
  const countSpan = document.createElement('span')
  const tipDiv = document.createElement('div')
  // span.innerText = null
  dom.id = udeskId
  countSpan.className = 'cs-count'
  tipDiv.id = 'cs-tip'
  // 头像
  const csType = CS.getCurrentCSType()
  img.src = CS[csType].serverImage
  // img.setAttribute('type', 'help')
  img.id = udeskId + '-img'
  dom.appendChild(img)
  dom.appendChild(countSpan)
  dom.appendChild(tipDiv)
  // 关闭icon
  const closeSpan = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  closeSpan.setAttribute('class', 'close-span')
  closeSpan.setAttribute('aria-hidden', 'true')
  closeSpan.innerHTML = '<use xlink:href="#wxm-cuohao2"></use>'
  tipDiv.appendChild(closeSpan)
  dom.style.display = 'none'
  document.body.appendChild(dom)
  const unread = localStorage.getItem('unreadcs')
  if (unread) {
    CS.setUnReadCSCount(unread)
  }
  // 收起
  closeSpan.onclick = CS.closeTip
  // 点击头像进入客服
  img.onclick = function () {
    if (dom.style.left === '-38px') {
      clearTimeout(window.cstiptimer)
      CS.openTip()
      return
    }
    CS.open(CS.getCurrentCSType())
  }
  // dom.onclick = CS.openTip
  // window.$CS = this
  // 支持拖动隐藏和显示
  // 定义变量，用于记录坐标和角度
  let startx, endx, nx
  // 开始触摸函数，event为触摸对象
  function touchs(event) {
    // event.preventDefault();// 阻止浏览器默认滚动事件
    if (event.type === 'touchstart') {
      // 通过if语句判断event.type执行了哪个触摸事件
      // console.log('开始');
      startx = Math.floor(event.touches[0].pageX) // 获取第一个坐标的X轴
    } else if (event.type === 'touchmove') {
      // 触摸中的坐标获取
      // console.log('滑动中');
      // let movex = Math.floor(event.touches[0].pageX);
    } else if (event.type === 'touchend' || event.type === 'touchcancel') {
      // 当手指离开屏幕或系统取消触摸事件的时候
      endx = Math.floor(event.changedTouches[0].pageX) // 获取最后的坐标位置
      nx = endx - startx // 获取开始位置和离开位置的距离
      // 判断滑动方向
      if (nx > 30) {
        window.$CS.openTip()
        // alert('右滑动');
        return false
      } else if (nx < -30) {
        // alert('左滑动');
        window.$CS.closeTip()
        return false
      }
    }
  }
  // 添加触摸事件的监听，并直行自定义触摸函数
  dom.addEventListener('touchstart', touchs, false)
  // dom.addEventListener('touchmove', touchs, false);
  dom.addEventListener('touchend', touchs, false)
}
CS.store = function (key, val) {
  if (val == undefined || val == null) {
    return sessionStorage.getItem(key)
  } else {
    sessionStorage.setItem(key, val)
  }
}
CS.removeStore = function (key) {
  sessionStorage.removeItem(key)
}
// 打开提示
CS.openTip = function () {
  const tipDom = document.getElementById('cs-tip')
  if (tipDom.style.display === 'none') {
    const type = tipDom.getAttribute('type')
    if (type === 'cs') {
      CS.removeStore('cs-tip-close')
    } else if (type === 'eye') {
      CS.removeStore('cs-eye-tip-close')
    }
    const csDom = document.getElementById(KEFU_DOM_ID)
    if (csDom.style.left === '-38px') {
      CS.animal(csDom, 'left', -38, 0, 0.5, 'px')
    }
    const tipItems = document.getElementById('cs-tip-items')
    if (tipItems && tipItems.children && tipItems.children.length) {
      CS.animalOpacity(tipDom, 0, 100, 5)
    }
  } else {
    CS.animalOpacity(tipDom, 100, 0, -5)
  }
  // CS.autoHideTip()
}
// 关闭提示
CS.closeTip = function () {
  const csDom = document.getElementById(KEFU_DOM_ID)
  const tipDom = document.getElementById('cs-tip')
  const type = tipDom.getAttribute('type')
  if (type === 'cs') {
    if (CS.store('cs-tip-close') === '1') {
      return
    }
    CS.store('cs-tip-close', '1')
  } else if (type === 'eye') {
    if (CS.store('cs-eye-tip-close') === '1') {
      return
    }
    CS.store('cs-eye-tip-close', '1')
  }
  CS.animal(csDom, 'left', 0, -38, -0.5, 'px')
  CS.animalOpacity(tipDom, 100, 0, -2)
}
// 显示云客服
CS.show = function (toPath) {
  //智能对话开启的话，不单独展示云客服
  if ($ZConfig.getVal('TM_CHAT_SWITCH')) {
    return
  }

  // console.log('CS.show ', toPath)
  // 创建默认项目
  const curCsType = CS.getCurrentCSType(toPath)
  const csConf = CS[curCsType]
  CS._createItems(csConf.defaultItems)
  document.getElementById(KEFU_DOM_ID + '-img').src = csConf.serverImage
  const csDom = document.getElementById(KEFU_DOM_ID)
  const tipDom = document.getElementById('cs-tip')
  tipDom.setAttribute('type', 'cs')
  tipDom.style.width = '170px'
  // tipDom.style.display = 'none'
  if (csDom.style.display === 'none') {
    csDom.style.left = CS.store('cs-tip-close') ? '-38px' : '0'
    CS.store('cs-tip-close') || !csConf.defaultItems || !csConf.defaultItems.length
      ? (tipDom.style.display = 'none')
      : CS.animalOpacity(tipDom, 0, 100, 5)
    CS.animalOpacity(csDom, 0, 100, 5)
  }
  CS.autoHideTip()
}
// 10s后自动关闭tip
CS.autoHideTip = function () {
  clearTimeout(window.cstiptimer)
  window.cstiptimer = setTimeout(() => {
    CS.closeTip()
  }, 10000)
}
/**
 * 显示鹰眼云客服
 * @param csType 云客服类型
 * @param items 提示项目
 * @param conf 配置项，
 *              width: 提示宽度
 */
CS.showEye = function (csType, items, conf = {}) {
  document.getElementById(KEFU_DOM_ID + '-img').src = CS[csType].serverImage
  const csDom = document.getElementById(KEFU_DOM_ID)
  const tipDom = document.getElementById('cs-tip')
  tipDom.setAttribute('type', 'eye')
  tipDom.style.width = conf.width || '170px'
  if (csDom.style.display === 'none') {
    csDom.style.left = '-38px'
    const eyeTipClosed = CS.store('cs-eye-tip-close')
    if (eyeTipClosed) {
      tipDom.style.display = 'none'
      CS.animalOpacity(csDom, 0, 100, 5)
    } else {
      CS.animal(csDom, 'left', -38, 0, 0.5, 'px')
      CS.animalOpacity(csDom, 0, 100, 2)
      CS.animalOpacity(tipDom, 0, 100, 1)
    }
  }
  CS._createItems(items)
  CS.autoHideTip()
}
/**
 * items: [{text:'点击我帮助',onclick: Fn}]
 * @param items
 * @private
 */
CS._createItems = function (items) {
  const tipDom = document.getElementById('cs-tip')
  let tipItemsDom = document.getElementById('cs-tip-items')
  if (tipItemsDom) {
    tipItemsDom.remove()
  }
  if (!items || items.length < 1) {
    tipDom.style.display = 'none'
    return
  }
  tipItemsDom = document.createElement('div')
  tipItemsDom.id = 'cs-tip-items'
  for (let i = 0; i < items.length; i++) {
    const itemDom = document.createElement('div')
    itemDom.innerHTML = items[i].text
    itemDom.onclick = items[i].onclick
    tipItemsDom.appendChild(itemDom)
  }
  tipDom.appendChild(tipItemsDom)
}
// 隐藏云客服/鹰眼入口 --  当前页面不支持云客服
CS.hide = function (immediately) {
  const csDom = document.getElementById(KEFU_DOM_ID)
  const tipDom = document.getElementById('cs-tip')
  if (immediately && csDom) {
    tipDom.style.display = 'none'
    csDom.style.display = 'none'
    return
  }
  if (csDom && csDom.style.display === 'block') {
    CS.animalOpacity(csDom, 100, 0, -5)
    if (tipDom) {
      CS.animalOpacity(tipDom, 100, 0, -10)
    }
  }
}
// 位置动画
CS.animal = function (dom, type, from, to, step = 1, sub) {
  setTimeout(() => {
    const next = from + step
    dom.style[type] = sub ? next + sub : next
    if (next == to) {
      return
    }
    CS.animal(dom, type, next, to, step, sub)
  }, 5)
}
// 隐藏动画
CS.animalOpacity = function (dom, from, to, step = 1) {
  setTimeout(() => {
    const next = from + step
    dom.style.opacity = next / 100
    if (from <= 0 && next > 0) {
      // 由隐藏至显示
      dom.style.display = 'block'
    } else if (from > 0 && next <= 0) {
      // 由显示至隐藏
      dom.style.display = 'none'
    }
    if (next >= 100 || next <= 0) {
      return
    }
    CS.animalOpacity(dom, next, to, step)
  }, 10)
}
// 获取当前客服类型
CS.getCurrentCSType = function (toPath) {
  const path = toPath || location.pathname
  if (path.startsWith('/hotel')) {
    return 'hotel'
  } else if (path.startsWith('/flight') || path.startsWith('/train')) {
    return 'ticket'
  }
  return 'help'
}
// 设置未读数量
CS.setUnReadCSCount = function (count) {
  localStorage.setItem('unreadcs', count)
  const dom = document.getElementsByClassName('cs-count')[0]
  if (dom) {
    dom.innerText = count
    dom.style.display = count > 0 ? 'block' : 'none'
  }
}
export default CS
